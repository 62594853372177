<template>
  <transition name="modal" v-if="showModalHeader" v-body-scroll-lock="showModalHeader">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog" role="document" style="max-width: 460px">
          <div class="modal-content">
            <div class="modal-body p-4">

              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      class="close"
                      aria-hidden="true"
                      @click="displaySelectionModal()"
                    ></span>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <h3 class="text-center">
                    {{ $t("language_country_of_origin") }}
                  </h3>
                </div>
              </div>

              <div class="row">
                <div class="col-12">

                  <div class="mt-3 p-4 bg-grey">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="selectedLanguage" class="font-weight-bold">{{ $t('language') }}:</label>
                          <v-select
                            class="ddm"
                            id="selectedLanguage"
                            :options="languages"
                            :get-option-label="option => $t(option.text)"
                            :reduce="(option) => option.value"
                            :clearable="false"
                            v-model="selectedLanguage"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="selectedVat" class="font-weight-bold">{{ $t('country_of_origin') }}:</label>
                          <v-select
                            class="ddm"
                            id="selectedVat"
                            :options="euCountries"
                            :get-option-label="option => $t(option.text)"
                            :reduce="(option) => option.value"
                            :clearable="false"
                            :disabled="checkAuthUser()"
                            v-model="selectedVat"
                          ></v-select>
                        </div>
                      </div>
                    </div>

                    <span v-if="!checkAuthUser()">{{ $t('country_of_origin_info') }}</span>
                    <span v-if="checkAuthUser()">{{ $t('country_of_origin_int_info') }}</span>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button
                        class="btn index-button btn-primary mt-4 pl-5 pr-5 float-right"
                        @click="saveSelection()"
                      >
                        {{ $t('save') }}
                      </button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { setLocale } from '@vee-validate/i18n';
import HttpMixin from '@/mixins/http.mixin'
import de from '@/assets/iso3166_eu_de.json'
import en from '@/assets/iso3166_eu_en.json'
import euVat from '@/assets/iso3166_eu_vat_rate.json'

export default {
  name: "SelectionModal",
  mixins: [HttpMixin],
  props: {
    showModalHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLanguage: "de",
      selectedVat: "DE",
      languages: [
        { text: "language_de", value: 'de' },
        { text: "language_en", value: 'en' }
      ]
    };
  },
  computed: {
    countriesOptions() {
      if (this.$root.$i18n.locale == "de")
        return de;
      return en;
    },
    vatCountryCode() {
      return this.$store.state.vatDisplay.country_code;
    },
    euCountries() {
      var euCountries = [
        { text: "vat_country.BE", value: 'BE' },
        { text: "vat_country.BG", value: 'BG' },
        { text: "vat_country.DE", value: 'DE' },
        { text: "vat_country.DK", value: 'DK' },
        { text: "vat_country.EE", value: 'EE' },
        { text: "vat_country.FI", value: 'FI' },
        { text: "vat_country.FR", value: 'FR' },
        { text: "vat_country.GR", value: 'GR' },
        { text: "vat_country.IE", value: 'IE' },
        { text: "vat_country.IT", value: 'IT' },
        { text: "vat_country.HR", value: 'HR' },
        { text: "vat_country.LV", value: 'LV' },
        { text: "vat_country.LT", value: 'LT' },
        { text: "vat_country.MT", value: 'MT' },
        { text: "vat_country.NL", value: 'NL' },
        { text: "vat_country.PL", value: 'PL' },
        { text: "vat_country.PT", value: 'PT' },
        { text: "vat_country.RO", value: 'RO' },
        { text: "vat_country.SE", value: 'SE' },
        { text: "vat_country.SK", value: 'SK' },
        { text: "vat_country.SI", value: 'SI' },
        { text: "vat_country.ES", value: 'ES' },
        { text: "vat_country.CZ", value: 'CZ' },
        { text: "vat_country.HU", value: 'HU' },
        { text: "vat_country.CY", value: 'CY' },
        { text: "vat_country.AT", value: 'AT' },
        { text: "vat_country.ZZ", value: 'ZZ' }
      ];

      var self = this;
      // sort drop down menu entries by i18n texts
      euCountries.sort(function (a, b) {
        return self.$t(a.text).localeCompare(self.$t(b.text));
      });

      return euCountries;
    },
  },
  beforeUpdate() {
    if (this.$route.params.locale == "de") {
      this.selectedLanguage = "de";
    } else if (this.$route.params.locale == "en") {
      this.selectedLanguage = "en";
    }

    // if the customers country doesn't belong to the EU then use ZZ for others
    if (typeof euVat[this.vatCountryCode] == 'undefined') {
      this.selectedVat = 'ZZ';
    } else {
      this.selectedVat = this.vatCountryCode;
    }
  },
  methods: {
    displaySelectionModal() {
      this.$emit("setShowSelectionModal", false);
    },
    async saveSelection() {
      // if the customer is logged in then the country and the VAT rate cannot be changed
      if (!this.checkAuthUser()) {
        this.$store.commit("setVatDisplay", { country_code: this.selectedVat, vat: euVat[this.selectedVat] })
      }

      setLocale(this.selectedLanguage)
      this.$root.$i18n.locale = this.selectedLanguage;

      if (this.checkAuthUser()) {
        await this.req('PATCH', '/v1/account/language',{
          language: (this.selectedLanguage == 'de' ? 'DE' : 'EN')
        })
        await this.req('PATCH', '/v1/customer/language',{
          language: (this.selectedLanguage == 'de' ? 'DE' : 'EN')
        })
      }

      if (this.selectedLanguage == 'de') {
        this.$router.push({ path: this.$route.path.replace('en', 'de') });
      } else {
        this.$router.push({ path: this.$route.path.replace('de', 'en') });
      }

      localStorage.setItem("language", this.selectedLanguage == 'de' ? 'DE' : 'EN');

      this.displaySelectionModal()
    }
  },
};
</script>
