import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const supportedLocales = {
  'de': { name: 'Deutsch', dir: 'ltr' },
  'en': { name: 'English', dir: 'ltr' }
}

let i18n;

function setup() {
  let defaultLocale;
  const lang = localStorage.getItem("language");
  if (lang != null) {
    defaultLocale = lang.toLowerCase();
  } else if (window.location.pathname.includes('/de/') || window.location.pathname.includes('/de')) {
    localStorage.setItem("language", 'DE');
    defaultLocale = 'de';
  } else if (window.location.pathname.includes('/en/') || window.location.pathname.includes('/en')) {
    localStorage.setItem("language", 'EN');
    defaultLocale = 'en';
  } else {
    let userDefaultLanguage = navigator.language || navigator.userLanguage;
    if(userDefaultLanguage.toLowerCase().includes('de')) {
      localStorage.setItem("language", 'DE');
      defaultLocale = 'de';
    } else {
      localStorage.setItem("language", 'EN');
      defaultLocale = 'en';
    }
  }

  i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: 'en',
    warnHtmlMessage: false,
    fallbackWarn: false,
    missingWarn: false,
    messages: {}
  });

  setLocale(defaultLocale);
  return i18n;
}

function setLocale(newLocale) {
  i18n.global.locale.value = newLocale;
  setDocumentAttributesFor(newLocale);
}

async function loadMessages(locale, fullPath) {
  let messages = await import(/* webpackChunkName: "locale-[request]" */ `../lang/translations/${locale}/text.json`);
  i18n.global.mergeLocaleMessage(locale, messages.default);
  messages = await import(/* webpackChunkName: "locale-[request]" */ `../assets/iso3166_${locale}.json`);
  i18n.global.mergeLocaleMessage(locale, messages.default);

  if (fullPath.includes('/tld/') || fullPath.includes('/promotions')) {
    messages = await import(/* webpackChunkName: "locale-[request]" */ `../lang/translations/${locale}/tld_info.json`);
    i18n.global.mergeLocaleMessage(locale, messages.default);
  }

  if (fullPath.includes('/terms-and-conditions')) {
    messages = await import(/* webpackChunkName: "locale-[request]" */ `../lang/translations/${locale}/terms_and_conditions.json`);
    i18n.global.mergeLocaleMessage(locale, messages.default);
  }

  if (fullPath.includes('/privacy-policy')) {
    messages = await import(/* webpackChunkName: "locale-[request]" */ `../lang/translations/${locale}/privacy_policy.json`);
    i18n.global.mergeLocaleMessage(locale, messages.default);
  }

  return nextTick();
}

function setDocumentAttributesFor(locale) {
  const htmlElement = document.querySelector('html');
  htmlElement.setAttribute('lang', locale);
  htmlElement.setAttribute('dir', supportedLocales[locale].dir);
}

export default {
  get vueI18n() {
    return i18n;
  },
  setup,
  setLocale,
  loadMessages,
}
