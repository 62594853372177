import { enableBodyScroll, disableBodyScroll /*, clearAllBodyScrollLocks*/ } from 'body-scroll-lock'

export default {
  install: ( app /*, options*/ ) => {
    app.directive('body-scroll-lock', {
      // on mount (inserted)
      mounted( el, binding ) {
        if (binding.value) {
          disableBodyScroll(el, {reserveScrollBarGap: true, allowTouchMove: () => true})
        }
      },

      updated( el, binding ) {
        if (binding.value) {
          disableBodyScroll(el)
        } else {
          enableBodyScroll(el)
        }
      },

      // on unmount (removed)
      unmounted( el ) {
        enableBodyScroll(el)
      }
    })
  },
}