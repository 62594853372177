
import { createStore } from 'vuex'
import { checkAuth } from '@/router/auth.js'
import euVat from "@/assets/iso3166_eu_vat_rate.json";

export default createStore({
  state() {
    // determine VAT and country code
    let vatDisplay;
    if (checkAuth()) {
      if (localStorage.getItem("localVatDisplay") === null) {
        const customerData = this.req("GET", "/v1/customer/me");
        let countryCodeforVat = customerData.data.data.country_code;
        if (typeof euVat[customerData.data.data.country_code] == 'undefined') {
          countryCodeforVat = 'ZZ';
        }
        
        let vatRate = 0;
        if (customerData.data.data.is_vat_applicable == 1) {
          vatRate = euVat[countryCodeforVat];
        }
        
        vatDisplay = {country_code: customerData.data.data.country_code, vat: vatRate};
      } else {
        vatDisplay = JSON.parse(localStorage.getItem('localVatDisplay'));
      }
    } else {
      if (localStorage.getItem("localVatDisplay") === null) {
        let vat;
        let countryCode;
        let defaultLocale;

        const lang = localStorage.getItem("language");
        if (lang != null) {
          defaultLocale = lang.toLowerCase();
        } else if (window.location.pathname.includes('/de/') || window.location.pathname.includes('/de')) {
          defaultLocale = 'de';
        } else if (window.location.pathname.includes('/en/') || window.location.pathname.includes('/en')) {
          defaultLocale = 'en';
        } else {
          let userDefaultLanguage = navigator.language || navigator.userLanguage;
          if(userDefaultLanguage.toLowerCase().includes('de')) {
            defaultLocale = 'de';
          } else {
            defaultLocale = 'en';
          }
        }

        // determine default VAT and country code when no local storage information is available
        if (defaultLocale == 'de') {
          vat = 19;
          countryCode = 'DE';
        } else {
          vat = 0;
          countryCode = 'ZZ';
        }

        vatDisplay = {country_code: countryCode, vat: vat};
      } else {
        vatDisplay = JSON.parse(localStorage.getItem('localVatDisplay'));
      }
    }

    return {
      shoppingCartCount: (checkAuth() ? null : JSON.parse(localStorage.getItem('localShoppingCart')||'[]').length),
      shoppingListCount: (checkAuth() ? null : JSON.parse(localStorage.getItem('localShoppingList')||'[]').length),
      prepaymentOrderCount: 0,
      vatDisplay: vatDisplay
    }
  },
  mutations: {
    shoppingCartCountChange(state, n) {
      state.shoppingCartCount += n;
    },
    shoppingCartCountReset(state, n = 0) {
      state.shoppingCartCount = n;
    },
    shoppingListCountChange(state, n) {
      state.shoppingListCount += n;
    },
    shoppingListCountReset(state, n = 0) {
      state.shoppingListCount = n;
    },
    prepaymentOrderCountChange(state, n) {
      state.prepaymentOrderCount += n;
    },
    prepaymentOrderCountReset(state, n = 0) {
      state.prepaymentOrderCount = n;
    },
    setVatDisplay(state, vatDisplay) {
      state.vatDisplay = vatDisplay
      localStorage.setItem("localVatDisplay", JSON.stringify(state.vatDisplay))
    }
  }
})
