<template>
  <footer class="footer">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 main">
        <div class="row" style="padding-left: 50px;">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <h5 class="footer-header">{{ $t("company") }}</h5>
            <div class="line"></div>
            <div class="footer-block">
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/about-us`}">{{ $t("aboutUs") }}</router-link>
              </div>
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/support`}">{{ $t("support") }}</router-link>
              </div>
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/jobs`}">{{ $t("jobs") }}</router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <h5>{{ $t("products") }}</h5>
            <div class="line"></div>
            <div class="footer-block">
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/domains`}">{{ $t("domains") }}</router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <h5>{{ $t("information") }}</h5>
            <div class="line"></div>
            <div class="footer-block">
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/payment-options`}"><span v-html="$t('paymentOptions')"></span></router-link>
              </div>
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/promotions`}">{{ $t("promotions") }}</router-link>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <h5>{{ $t("legal") }}</h5>
            <div class="line"></div>
            <div class="footer-block">
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/legal-notice`}">{{ $t("legalNotice") }}</router-link>
              </div>
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/privacy-policy`}"><span v-html="$t('privacyPolicy')"></span></router-link>
              </div>
              <div class="row">
                <router-link class="col" :to="{path: `/${this.$root.$i18n.locale}/terms-and-conditions`}">{{ $t("termsAndConditionsAbbr") }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 secondary">
      <img :src="`/img/payment_method/payment_method_all_${this.$root.$i18n.locale}.svg`" width="241" height="101" :alt="$t('paymentOptions_all')"/>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>
