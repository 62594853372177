<style lang="scss" scoped>
  @import './../../../assets/css/homepage.scss';
</style>

<template>
  <vue-title :title="$t('homepageTitle')+$t('titleUniversal')"></vue-title>
  <div>        
    <div class="wrapper">
      <div class="content">

        <div id="header-area">
          <div id="header-content">
            <div class="header-search">
              <h1 class="pb-4">{{ $t("registerDomainNow") }}</h1>

              <form class="form-inline" style="flex-flow: row;">
                <label class="sr-only" for="domain-name">{{
                  $t("searchDomain")
                }}</label>
                
                <input
                  v-model="domainName"
                  type="text"
                  id="domain-name"
                  class="register-input"
                  minlength="1"
                  maxlength="63"
                  :placeholder="$t('searchDomain')"
                />
                <button @click="domainSearch" class="btn register-button btn-primary" type="submit">
                  <img src="/img/homepage/search.svg" :alt="$t('searchDomain')" width="25" height="25" />
                </button>
              </form>

            </div>
          </div>
        </div>

        <div class="special">
          <h2 class="pb-4">{{ $t("thisMakesUsSpecial") }}</h2>
          <div class="container">
            <div class="card-deck mb-3 text-center">
              <div class="card mb-4">
                <div class="card-body d-flex flex-column flex-sm-column">
                  <img src="/img/homepage/operation_mode.svg" class="img-fluid" :alt="$t('block1HeaderAlt')" />
                  <h3 v-html="$t('block1Header')"></h3>
                  <p>{{ $t("block1") }}</p>
                  <router-link class="mt-auto pt-2 pb-3" :to="{path: `/${this.$root.$i18n.locale}/features`,}">
                    <button class="btn btn-primary index-button mt-auto">{{ $t("details") }}</button>
                  </router-link>
                </div>
              </div>
              <div class="card mb-4">
                <div class="card-body d-flex flex-column flex-sm-column">
                  <img src="/img/homepage/help.svg" class="img-fluid" :alt="$t('block2HeaderAlt')" />
                  <h3 v-html="$t('block2Header')"></h3>
                  <p>{{ $t("block2") }}</p>
                  <router-link class="mt-auto pt-2 pb-3" :to="{path: `/${this.$root.$i18n.locale}/support`,}">
                    <button class="btn btn-primary index-button mt-auto">{{ $t("details") }}</button>
                  </router-link>
                </div>
              </div>
              <div class="card mb-4">
                <div class="card-body d-flex flex-column flex-sm-column">
                  <img src="/img/homepage/dns_zones.svg" class="img-fluid" :alt="$t('block3HeaderAlt')" />
                  <h3 v-html="$t('block3Header')"></h3>
                  <p>{{ $t("block3") }}</p>
                  <router-link class="mt-auto pt-2 pb-3" :to="{path: `/${this.$root.$i18n.locale}/features`, hash: '#dns'}">
                    <button class="btn btn-primary index-button mt-auto">{{ $t("details") }}</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            id="main-carousel"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="bullets carousel-indicators">
              <li
                data-target="#main-carousel"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#main-carousel" data-slide-to="1"></li>
              <!-- coming soon ;D
              <li data-target="#main-carousel" data-slide-to="2"></li>
              -->
            </ol>
           
            <div class="carousel-inner">

              <div class="carousel-item active">
                <picture>
                  <source srcset="/img/homepage/carousel_mobile_tan.webp" type="image/webp" id="test" class="carousel-img d-block w-100" :alt="$t('slider1Header')"/>
                  <source srcset="/img/homepage/carousel_mobile_tan.jpg" type="image/jpeg" id="test" class="carousel-img d-block w-100" :alt="$t('slider1Header')"/>
                  <img src="/img/homepage/carousel_mobile_tan.jpg" id="test" class="carousel-img d-block w-100" :alt="$t('slider1Header')"/>
                </picture>
                <div class="carousel-caption">
                  <h4 v-html="$t('slider1Header')"></h4>
                  <p>{{ $t('slider1') }}</p>
                </div>
              </div>
              <div class="carousel-item">
                <picture>
                  <source srcset="/img/homepage/carousel_domain_contact.webp" type="image/webp" id="test" class="carousel-img d-block w-100" :alt="$t('slider2Header')"/>
                  <source srcset="/img/homepage/carousel_domain_contact.jpg" type="image/jpeg" id="test" class="carousel-img d-block w-100" :alt="$t('slider2Header')"/>
                  <img src="/img/homepage/carousel_domain_contact.jpg" id="test" class="carousel-img d-block w-100" :alt="$t('slider1Header')"/>
                </picture>
                <div class="carousel-caption">
                  <h4>{{ $t('slider2Header') }}</h4>
                  <p>{{ $t('slider2') }}</p>
                </div>
              </div>
              <!-- coming soon ;D
              <div class="carousel-item">
                <img src="/img/xyz.jpg" class="d-block w-100" :alt="$t('slider3Header')" />
                <div class="carousel-caption d-none d-md-block">
                  <h4>{{$t('slider3Header')}}</h4>
                  <p>{{$t('slider3')}}</p>
                </div>
              </div>
              -->
            </div>
            <a
              class="carousel-control-prev"
              href="#main-carousel"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#main-carousel"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        
        <div>
          <div class="row faq-and-testimonals">
            <div class="faq col-12 col-sm-12 col-md-12 col-lg-5">
              <h3 class="pb-4 pt-4 headline">{{ $t("faq") }}</h3>
              <ul>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=5&id=19&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=5&id=19&artlang=en'" target="_blank">
                    {{ $t("homepage_question1") }}
                  </a>
                </li>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=6&id=20&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=6&id=20&artlang=en'" target="_blank">
                    {{ $t("homepage_question2") }}
                  </a>
                </li>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=2&id=3&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=2&id=3&artlang=en'" target="_blank">
                    {{ $t("homepage_question3") }}
                  </a>
                </li>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=2&id=9&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=2&id=9&artlang=en'" target="_blank">
                    {{ $t("homepage_question4") }}
                  </a>
                </li>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=3&id=16&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=3&id=16&artlang=en'" target="_blank">
                    {{ $t("homepage_question5") }}
                  </a>
                </li>
                <li>
                  <a :href="this.$root.$i18n.locale == 'de' ? this.getHelpSystemUrl() + '/?action=faq&cat=3&id=17&artlang=de' : this.getHelpSystemUrl() + '/?action=faq&cat=3&id=17&artlang=en'" target="_blank">
                    {{ $t("homepage_question6") }}
                  </a>
                </li>
                <li></li>
              </ul>
            </div>

            <div class="testimonials col-12 col-sm-12 col-md-12 col-lg-7">
              <h3 class="pb-4 pt-4 headline">{{ $t("whatCustomersAreSaying") }}</h3>
              
              <div>
                <div id="testimonal-carousel" class="carousel slide" data-ride="carousel" data-interval="10000">
                  <ol class="bullets carousel-indicators">
                    <li data-target="#testimonal-carousel" data-slide-to="0" class="active"></li>
                    <li data-target="#testimonal-carousel" data-slide-to="1"></li>
                    <!--<li data-target="#testimonal-carousel" data-slide-to="2"></li>-->
                  </ol>
                
                  <div class="carousel-inner">
                    <div class="background-box">

                      <div class="carousel-item active">
                        <div class="testimonial-bar">
                          <p class="headline-name">{{ $t('testimonial_name1') }}&nbsp;&nbsp;</p>
                          <p class="headline-job">–&nbsp;&nbsp;{{ $t('testimonial_job1') }}</p>
                          <div class="rating-bar">
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                           </div>
                        </div>
                        <div class="content-wrapper text-left pl-2 pr-2">
                          <div class="card-body d-flex flex-column flex-sm-column d-block">
                            <p class="font-weight-bold">{{ $t('testimonial_headline1') }}</p>
                            <div class="text pb-3" v-html="$t('testimonial_text1')"></div>
                          </div>
                        </div>
                      </div>

                      <div class="carousel-item">
                        <div class="testimonial-bar">
                          <p class="headline-name">{{ $t('testimonial_name2') }}&nbsp;&nbsp;</p>
                          <p class="headline-job">–&nbsp;&nbsp;{{ $t('testimonial_job2') }}</p>
                          <div class="rating-bar">
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating-blank"></span>
                           </div>
                        </div>
                        <div class="content-wrapper text-left pl-2 pr-2">
                          <div class="card-body d-flex flex-column flex-sm-column d-block w-100">
                            <p class="font-weight-bold">{{ $t('testimonial_headline2') }}</p>
                            <div class="text pb-3" v-html="$t('testimonial_text2')"></div>
                          </div>
                        </div>
                      </div>
<!--
                      <div class="carousel-item">
                        <div class="testimonial-bar">
                          <p class="headline-name">{{ $t('testimonial_name3') }}&nbsp;&nbsp;</p>
                          <p class="headline-job">–&nbsp;&nbsp;{{ $t('testimonial_job3') }}</p>
                          <div class="rating-bar">
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating"></span>
                            <span class="rating-blank"></span>
                           </div>
                        </div>
                        <div class="content-wrapper text-left pl-2 pr-2">
                          <div class="card-body d-flex flex-column flex-sm-column d-block w-100">
                            <p class="font-weight-bold">{{ $t('testimonial_headline3') }}</p>
                            <div class="text pb-3" v-html="$t('testimonial_text3')"></div>
                          </div>
                        </div>
                      </div>
-->
                    </div>

                    <a
                      class="carousel-control-prev"
                      href="#testimonal-carousel"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#testimonal-carousel"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpMixin from '@/mixins/http.mixin'

export default {
  name: "index",
  mixins: [HttpMixin],
  components: {},
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  methods: {
    onSlidestart() {
      this.sliding = true;
    },
    domainSearch() {
      if (typeof this.domainName !== 'undefined' &&
          this.domainName.length != 0 &&
          this.domainName.trim().length != 0
      ) {
        this.$router.push({path: `/${this.$root.$i18n.locale}/register-domain`, query: { domain: this.domainName }});
      }
    },
  },
};
</script>
