/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import { setLocale } from '@vee-validate/i18n';

import {checkAuth} from './auth.js'
import {checkPrivilege} from './privilege.js'

import i18n from '../i18n'

import Index from '@/views/public/homepage/Index'

// header
import DomainSearch from '@/views/public/header/DomainSearch'
const Prices = () =>
  import('@/views/public/header/Prices')
const Features = () =>
  import('@/views/public/header/Features')

// features
const DomainFeatures = () =>
  import('@/views/public/features/Domain')
const AccountFeatures = () =>
  import('@/views/public/features/Account')
const ForwardingFeatures = () =>
  import('@/views/public/features/Forwarding')
const NameserverFeatures = () =>
  import('@/views/public/features/Nameserver')

// sites with additional information
const TldInfo = () =>
  import('@/views/public/info/TldInfo')
const RegistryTermsAndConditions = () =>
  import('@/views/public/info/RegistryTermsAndConditions')

// account (register, login, password reset)
const Auth = () =>
  import('@/views/public/account/Auth')
const ResetPaswordRequest = () =>
  import('@/views/public/account/ResetPasswordRequest')
const ResetPaswordVerification = () =>
  import('@/views/public/account/ResetPasswordVerification')
const EmailVerification = () =>
  import('@/views/public/account/EmailVerification')
const EmailChangeVerification = () =>
  import('@/views/public/account/EmailChangeVerification')

// footer
const AboutUs = () =>
  import('@/views/public/footer/AboutUs')
const Jobs = () =>
  import('@/views/public/footer/Jobs')
const Domains = () =>
  import('@/views/public/footer/Domains')
const PaymentOptions = () =>
  import('@/views/public/footer/PaymentOptions')
const Promotions = () =>
  import('@/views/public/footer/Promotions')
const PrivacyPolicy = () =>
  import('@/views/public/footer/PrivacyPolicy')
const LegalNotice = () =>
  import('@/views/public/footer/LegalNotice')
const TermsAndConditions = () =>
  import('@/views/public/footer/TermsAndConditions')
const Support = () =>
  import('@/views/public/footer/Support')

// newsletter
const GeneralNewsletterSubscribe = () =>
  import('@/views/public/newsletter/GeneralNewsletterSubscribe')
const TechNewsletterSubscribe = () =>
  import('@/views/public/newsletter/TechNewsletterSubscribe')
const GeneralNewsletterUnsubscribe = () =>
  import('@/views/public/newsletter/GeneralNewsletterUnsubscribe')
const TechNewsletterUnsubscribe = () =>
  import('@/views/public/newsletter/TechNewsletterUnsubscribe')

// customer area - dashboard
const Dashboard = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/dashboard/Dashboard')

// customer area - account
const MasterData = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/account/MasterData')
const CompleteRegistration = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/account/CompleteRegistration')

// customer area - settings
const Security = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/settings/Security')
const Newsletter = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/settings/Newsletter')
const OperationMode = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/settings/OperationMode')

// customer area - accounting
const Deposit = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/accounting/Deposit')
const History = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/accounting/History')
const Payout = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/accounting/Payout')
const Invoice = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/accounting/Invoice')

// customer area - dns
const NameserverSets = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/dns/NameserverSets')
const DnsZones = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/dns/DnsZones')

// customer area - domain
const Events = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/domain/Events')
const DomainOverview = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/domain/DomainOverview')
const DomainContacts = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/domain/DomainContacts')
const Renewals = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/domain/Renewals')

// customer area - shopping
const OpenOrders = () =>
  import(/* webpackChunkName: "grp-customer-area" */ '@/views/private/ordering/OpenOrders')

// public & customer area - shopping cart
const ShoppingCart = () =>
  import('@/views/private/ordering/ShoppingCart')
const WatchList = () =>
  import('@/views/private/ordering/WatchList')

// admin
const AdminDashboard = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/AdminDashboard')
const Transactions = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/Transactions')
const TransactionsUpload = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/TransactionsUpload')
const Accounting = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/Accounting')
const RepairTools = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/RepairTools')
const DnsZoneSync = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/DnsZoneSync')
const AccountingExport = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/AccountingExport')
const WednesdayDeal = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/WednesdayDeal')
const ManageCustomer = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/ManageCustomer')
const ManagePaymentMethod = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/ManagePaymentMethod')
const PriceChange = () =>
  import(/* webpackChunkName: "grp-admin-dashboard" */ '@/views/private/admin/PriceChange')


function redirectDefaultLanguage() {
  const lang = localStorage.getItem("language");

  if (lang != null) {
    setLocale(lang.toLowerCase());
    return '/' + lang.toLowerCase();

  } else {
    let userDefaultLanguage = navigator.language || navigator.userLanguage;

    if(userDefaultLanguage.toLowerCase().includes('de')) {
      setLocale('de');
      localStorage.setItem("language", 'DE');
      return '/de'
      
    } else {
      setLocale('en');
      localStorage.setItem("language", 'EN');
      return '/en'
    }
  }
}

const locales = ['de', 'en']

const routes = [{
  path: '/:locale',
  component: {
    template: "<router-view></router-view>"
  },
  children: [
    {
      path: '',
      name: 'index',
      component: Index,
      meta: {
        de: {
          //title: 'Index',
          metaTags: [
            {
              name: 'description',
              content: 'manotori ist Ihr Domainregistrar für die professionelle Domainverwaltung. Einsteiger und Profis können hier günstige Domains kaufen.',
            }
          ]
        },
        en: {
          //title: 'Index',
          metaTags: [
            {
              name: 'description',
              content: 'manotori is your domain registrar for professional domain management. Beginners and professionals can buy cheap domains here.',
            }
          ]
        },
      }
    },
    {
      path: 'login',
      name: 'auth',
      component: Auth,
      beforeEnter: (to, from, next) => {
        if(checkAuth('REGISTRATION_PENDING')){
          next({path: `${redirectDefaultLanguage()}/complete-verification`})
        }
        else if(checkAuth()){
          next({path: `${redirectDefaultLanguage()}/s/dashboard`})
        }else{
          next()
        }
      },
      meta: {
        de: {
          //title: 'Auth',
          metaTags: [
            {
              name: 'description',
              content: 'Unser benutzerfreundlicher Kundenbereich bietet Ihnen die Möglichkeit alle Einstellungen für Ihre Domains selbständig vorzunehmen.',
            }
          ]
        },
        en: {
          //title: 'Auth',
          metaTags: [
            {
              name: 'description',
              content: 'Our user-friendly customer area gives you the possibility to make all domain settings on your own.',
            }
          ]
        },
      }
    },
    {
      path: 'reset-password/:email/:token',
      name: 'reset-password',
      component: ResetPaswordVerification
    },
    {
      path: 'forgot-password',
      name: 'forgot-password',
      component: ResetPaswordRequest,
      meta: {
        de: {
          //title: 'ResetPaswordRequest',
          metaTags: [
            {
              name: 'description',
              content: 'Um Zugang zum Kundenbereich zu erhalten, benötigen Sie Ihre Zugangsdaten. Sie können ein verloren gegangenes Passwort hier zurücksetzen.',
            }
          ]
        },
        en: {
          //title: 'ResetPaswordRequest',
          metaTags: [
            {
              name: 'description',
              content: 'To get access to the customer area, you need your login data. You can reset a forgotten password here.',
            }
          ]
        },
      }
    },
    {
      path: 'email-verification/:token',
      name: 'EmailVerification',
      component: EmailVerification
    },
    {
      path: 'email-change-verification/:token',
      name: 'EmailChangeVerification',
      component: EmailChangeVerification
    },
    {
      path: 'complete-verification',
      name: 'complete-verification',
      component: CompleteRegistration,
      beforeEnter: (to, from, next) => {
        if(checkAuth("REGISTRATION_PENDING")){
          next()
        }else{
          next({path: `${redirectDefaultLanguage()}/s`})
        }

      },
    },

    {
      path: 'about-us',
      name: 'about-us',
      component: AboutUs,
      meta: {
        de: {
          //title: 'AboutUs',
          metaTags: [
            {
              name: 'description',
              content: 'manotori ist frisch, innovativ, kundenorientiert und der richtige Partner, wenn es um die einfache und professionelle Verwaltung von Domains geht.',
            }
          ]
        },
        en: {
          //title: 'AboutUs',
          metaTags: [
            {
              name: 'description',
              content: 'manotori is fresh, innovative, customer-focused and the right partner when it comes to easy and professional domain management.',
            }
          ]
        },
      }
    },
    {
      path: 'jobs',
      name: 'jobs',
      component: Jobs,
      meta: {
        de: {
          //title: 'Jobs',
          metaTags: [
            {
              name: 'description',
              content: 'Haben Sie Lust Teil eines ambitioniertes Teams zu werden und den Domainmarkt gemeinsam mit uns zu gestalten? Ihre neue Stelle wartet auf Sie.',
            }
          ]
        },
        en: {
          //title: 'Jobs',
          metaTags: [
            {
              name: 'description',
              content: 'Do you want to become part of an ambitious team and shape the domain market together with us? Your new job is waiting for you.',
            }
          ]
        },
      }
    },
    {
      path: 'domains',
      name: 'domains',
      component: Domains,
      meta: {
        de: {
          //title: 'Domains',
          metaTags: [
            {
              name: 'description',
              content: 'Wir bieten Ihnen TLDs weltweit an und geben Ihnen weitere Infos. Lassen Sie sich inspirieren und registrieren Sie Ihren verfügbaren Domainnamen!',
            }
          ]
        },
        en: {
          //title: 'Domains',
          metaTags: [
            {
              name: 'description',
              content: 'We offer TLDs worldwide and give you more information. Get inspired and register your available domain name!',
            }
          ]
        },
      }
    },
    {
      path: 'payment-options',
      name: 'payment-options',
      component: PaymentOptions,
      meta: {
        de: {
          //title: 'PaymentOptions',
          metaTags: [
            {
              name: 'description',
              content: 'Für die Zahlung bieten wir Ihnen die Flexibilität, die Sie benötigen. Möchten Sie eine Domain kaufen, zahlen Sie per PayPal, Kreditkarte oder Banküberweisung.',
            }
          ]
        },
        en: {
          //title: 'PaymentOptions',
          metaTags: [
            {
              name: 'description',
              content: 'For the payment we offer you all the flexibility you need. If you want to buy a domain, pay via PayPal, credit card or bank transfer.',
            }
          ]
        },
      }
    },
    {
      path: 'promotions',
      name: 'promotions',
      component: Promotions,
      meta: {
        de: {
          //title: 'Promotions',
          metaTags: [
            {
              name: 'description',
              content: 'Profitieren Sie von unseren günstigen Domain Preisen und attraktiven Preisaktionen. Ein ganz besonderer Vorteil sind unsere Knallerangebote!',
            }
          ]
        },
        en: {
          //title: 'Promotions',
          metaTags: [
            {
              name: 'description',
              content: 'Benefit from our cheap domain prices and attractive price campaigns. A very special advantage are our weekly promotions!',
            }
          ]
        },
      }
    },
    {
      path: 'features',
      name: 'features',
      component: Features,
      meta: {
        de: {
          //title: 'Features',
          metaTags: [
            {
              name: 'description',
              content: 'Einsteiger können ohne Fachkenntnisse eine Domain registrieren und verwalten. Profis nutzen umfangreiche Möglichkeiten zur erfolgreichen Domain Verwaltung.',
            }
          ]
        },
        en: {
          //title: 'Features',
          metaTags: [
            {
              name: 'description',
              content: 'Beginners can register and manage a domain without any expertise. Professionals use extensive possibilities for successful domain management.',
            }
          ]
        },
      }
    },
    {
      path: 'features/domain',
      name: 'features/domain',
      component: DomainFeatures,
      meta: {
        de: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'DE DESC TODO',
            }
          ]
        },
        en: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'EN DESC TODO',
            }
          ]
        },
      }
    },
    {
      path: 'features/account',
      name: 'features/account',
      component: AccountFeatures,
      meta: {
        de: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'DE DESC TODO',
            }
          ]
        },
        en: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'EN DESC TODO',
            }
          ]
        },
      }
    },
    {
      path: 'features/forwarding',
      name: 'features/forwarding',
      component: ForwardingFeatures,
      meta: {
        de: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'DE DESC TODO',
            }
          ]
        },
        en: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'EN DESC TODO',
            }
          ]
        },
      }
    },
    {
      path: 'features/nameserver',
      name: 'features/nameserver',
      component: NameserverFeatures,
      meta: {
        de: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'DE DESC TODO',
            }
          ]
        },
        en: {
          //title: 'TODO',
          metaTags: [
            {
              name: 'description',
              content: 'EN DESC TODO',
            }
          ]
        },
      }
    },
    {
      path: 'legal-notice',
      name: 'legal-notice',
      component: LegalNotice,
      meta: {
        de: {
          //title: 'LegalNotice',
          metaTags: [
            {
              name: 'description',
              content: 'Impressum der manotori GmbH, Ihrem Domainregistrar für die professionelle Verwaltung von Domains.',
            }
          ]
        },
        en: {
          //title: 'LegalNotice',
          metaTags: [
            {
              name: 'description',
              content: 'Legal notice of manotori GmbH, your domain registrar for professional domain management.',
            }
          ]
        },
      }
    },
    {
      path: 'privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: {
        de: {
          //title: 'PrivacyPolicy',
          metaTags: [
            {
              name: 'description',
              content: 'Der Schutz Ihrer Daten hat für uns höchste Priorität. Ihre Sicherheit ist uns sehr wichtig, daher informieren wir Sie über die Verarbeitung von Daten.',
            }
          ]
        },
        en: {
          //title: 'PrivacyPolicy',
          metaTags: [
            {
              name: 'description',
              content: 'The protection of your data is our highest priority. Your security is very important to us, so we inform you about the processing of data.',
            }
          ]
        },
      }
    },
    {
      path: 'terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsAndConditions,
      meta: {
        de: {
          //title: 'TermsAndConditions',
          metaTags: [
            {
              name: 'description',
              content: 'Allgemeine Geschäftsbedingungen der manotori GmbH, Ihrem Domainregistrar für die professionelle Verwaltung von Domains.',
            }
          ]
        },
        en: {
          //title: 'TermsAndConditions',
          metaTags: [
            {
              name: 'description',
              content: 'General Terms and Conditions of manotori GmbH, your domain registrar for professional domain management.',
            }
          ]
        },
      }
    },
    {
      path: 'registry-toc',
      name: 'registry-toc',
      component: RegistryTermsAndConditions,
      meta: {
        de: {
          //title: 'RegistryTermsAndConditions',
          metaTags: [
            {
              name: 'description',
              content: 'Übersicht der Vergabebedingungen der jeweiligen Registrierungsstellen.',
            }
          ]
        },
        en: {
          //title: 'RegistryTermsAndConditions',
          metaTags: [
            {
              name: 'description',
              content: 'RegistryTermsAndConditions en desc.',
            }
          ]
        },
      }
    },
    {
      path: 'tld/:tld-domain',
      name: 'tld',
      component: TldInfo,
      meta: {
        de: {
          //title: 'TldInfo',
          metaTags: [
            {
              name: 'description',
              content: 'Ihre eigene {tld} Domain günstig kaufen. Mit unserer komfortablen Bedienoberfläche können Sie schnell und einfach Ihre Wunschdomain registrieren/transferieren.',
            }
          ]
        },
        en: {
          //title: 'TldInfo',
          metaTags: [
            {
              name: 'description',
              content: 'Buy your own {tld} domain cheap. With our convenient user interface you can quickly and easily register/transfer your desired domain.',
            }
          ]
        },
      },
    },
    {
      path: 'register-domain',
      name: 'register-domain',
      component: DomainSearch,
      props: { searchMode: 'REGISTRATION' },
      meta: {
        de: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'Das Registrieren einer Domain ist innerhalb weniger Momente erledigt. So können Sie ohne großen Aufwand Ihre Wunschdomain prüfen und sichern.',
            }
          ]
        },
        en: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'The search for a domain is done within a few moments. So you can check and register your desired domain without much effort.',
            }
          ]
        },
      }
    },
    {
      path: 'check-domain',
      name: 'check-domain',
      component: DomainSearch,
      props: { searchMode: 'REGISTRATION' },
      meta: {
        de: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'Das Prüfen der Verfügbarkeit einer Domain ist einfach möglich. So können Sie ohne großen Aufwand Ihre Wunschdomain reservieren/registrieren.',
            }
          ]
        },
        en: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'It is easy to check the availability of a domain. So you can reserve/register your desired domain name without much effort.',
            }
          ]
        },
      }
    },
    {
      path: 'transfer-domain',
      name: 'transfer-domain',
      component: DomainSearch,
      props: { searchMode: 'TRANSFER' },
      meta: {
        de: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'Das Transferieren einer Domain ist schnell und einfach möglich. So können Sie ohne großen Aufwand Ihre Wunschdomain umziehen und professionell verwalten.',
            }
          ]
        },
        en: {
          //title: 'DomainSearch',
          metaTags: [
            {
              name: 'description',
              content: 'Transferring a domain is fast and easy. So you can transfer your domain without much effort and manage it professionally.',
            }
          ]
        },
      }
    },
    // ------------------------------------------------------------------------------------
    {
      path: 'support',
      name: 'support',
      component: Support,
      meta: {
        de: {
          //title: 'Support',
          metaTags: [
            {
              name: 'description',
              content: 'Uns ist besonders wichtig, dass Sie ohne Wartezeiten zur Lösung gelangen. Mit unserer umfangreichen Wissensdatenbank beantworten wir Ihre Fragen sofort.',
            }
          ]
        },
        en: {
          //title: 'Support',
          metaTags: [
            {
              name: 'description',
              content: 'It is very important to us that you get to the solution without waiting. With our extensive knowledge base, we provide instant answers to your questions.',
            }
          ]
        },
      }
    },
    // ------------------------------------------------------------------------------------
    {
      path: 'prices',
      name: 'prices',
      component: Prices,
      meta: {
        de: {
          //title: 'Prices',
          metaTags: [
            {
              name: 'description',
              content: 'Unsere Preisliste zeigt Ihnen unsere attraktiven Domain Preise. So bekommen Sie einen schnellen Überblick über die Kosten für Registrierung, Transfer und mehr.',
            }
          ]
        },
        en: {
          //title: 'Prices',
          metaTags: [
            {
              name: 'description',
              content: 'Our price list shows you our attractive domain prices. So you get a quick overview of the costs for registration, transfer and more.',
            }
          ]
        },
      }
    },
    // ------------------------------------------------------------------------------------
    {
      path: 'newsletter/subscribe/:token',
      name: 'GeneralNewsletterSubscribe',
      component: GeneralNewsletterSubscribe
    },
    {
      path: 'newsletter/tech/subscribe/:token',
      name: 'TechNewsletterSubscribe',
      component: TechNewsletterSubscribe
    },
    {
      path: 'newsletter/unsubscribe/:token',
      name: 'GeneralNewsletterUnsubscribe',
      component: GeneralNewsletterUnsubscribe
    },
    {
      path: 'newsletter/tech/unsubscribe/:token',
      name: 'TechNewsletterUnsubscribe',
      component: TechNewsletterUnsubscribe
    },
    {
      path: 'shopping-cart',
      name: 'public-shopping-cart',
      component: ShoppingCart,
      meta: {
        de: {
          //title: 'ShoppingCart',
          metaTags: [
            {
              name: 'description',
              content: 'Unser intuitives Bedienkonzept führt Sie gezielt durch den Bestellprozess. So können Sie bereits nach wenigen Augenblicken Ihre eigene Domain kaufen.',
            }
          ]
        },
        en: {
          //title: 'ShoppingCart',
          metaTags: [
            {
              name: 'description',
              content: 'Our intuitive user interface guides you through the ordering process. This way you can buy your own domain after just a few moments.',
            }
          ]
        },
      }
    },
    {
      path: 'watch-list',
      name: 'public-watch-list',
      component: WatchList,
      meta: {
        de: {
          //title: 'WatchList',
          metaTags: [
            {
              name: 'description',
              content: 'Unsere Merkliste speichert Ihre Domain-Ideen für einen späteren Einkauf. Mit nur einem Klick übertragen Sie Ihre Wunschdomains in den Warenkorb.',
            }
          ]
        },
        en: {
          //title: 'WatchList',
          metaTags: [
            {
              name: 'description',
              content: 'Our watch list stores your domain ideas for a later purchase. With just one click you move your desired domains into the shopping cart.',
            }
          ]
        },
      }
    },
    {
      path: 's',
      name: 's',
      component: {
        template: "<router-view></router-view>"
      },
      beforeEnter: (to, from, next) => {
        if(checkAuth('REGISTRATION_PENDING')) {
          next({path: `${redirectDefaultLanguage()}/complete-verification`})
        }
        else if(checkAuth()) {
          next()
        } else {
          next({path: `${redirectDefaultLanguage()}/login`, query: {url: to.fullPath, access: false}})
          this.$store.commit('shoppingCartCountReset')
          this.$store.commit('shoppingListCountReset')
          this.$store.commit('prepaymentOrderCountReset')
        }
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'complete-registration',
          name: 'CompleteRegistration',
          component: CompleteRegistration
        },
        {
          path: 'master-data',
          name: 'master-data',
          component: MasterData
        },
        {
          path: 'shopping-cart',
          name: 'shopping-cart',
          component: ShoppingCart
        },
        {
          path: 'domain-contacts',
          name: 'domain-contacts',
          component: DomainContacts
        },
        {
          path: 'security',
          name: 'security',
          component: Security
        },
        {
          path: 'newsletter',
          name: 'newsletter',
          component: Newsletter
        },
        {
          path: 'deposit',
          name: 'deposit',
          component: Deposit
        },
        {
          path: 'account-history',
          name: 'account-history',
          component: History
        },
        {
          path: 'payout',
          name: 'payout',
          component: Payout
        },
        {
          path: 'invoice',
          name: 'invoice',
          component: Invoice
        },
        {
          path: 'operation-mode',
          name: 'operation-mode',
          component: OperationMode
        },
        {
          path: 'nameserver-sets',
          name: 'nameserver-sets',
          component: NameserverSets
        },
        {
          path: 'open-orders',
          name: 'open-orders',
          component: OpenOrders
        },
        {
          path: 'watch-list',
          name: 'watch-list',
          component: WatchList
        },
        {
          path: 'events',
          name: 'events',
          component: Events
        },
        {
          path: 'domain-overview',
          name: 'domain-overview',
          component: DomainOverview
        },
        {
          path: 'renewals',
          name: 'renewals',
          component: Renewals
        },
        {
          path: 'dns-zones',
          name: 'dns-zones',
          component: DnsZones
        },
        {
          path: 'admin',
          name: 'admin',
          component: {
            template: "<router-view></router-view>"
          },
          beforeEnter: (to, from, next) => {
            if(!checkPrivilege('FINANCE_ADMIN') && !checkPrivilege('DOMAIN_ADMIN')) {
              next({path: `${redirectDefaultLanguage()}/login`, query: {url: to.fullPath, access: false}})
            } else {
              next()
            }
          },
          children: [
            {
              path: 'dashboard',
              name: 'dashboard',
              component: AdminDashboard
            },
            {
              path: 'transactions',
              name: 'Transactions',
              component: Transactions
            },
            {
              path: 'transactions-upload',
              name: 'TransactionsUpload',
              component: TransactionsUpload
            },
            {
              path: 'accounting',
              name: 'Accounting',
              component: Accounting
            },
            {
              path: 'repair-tools',
              name: 'RepairTools',
              component: RepairTools
            },
            {
              path: 'dns-zone-sync',
              name: 'DnsZoneSync',
              component: DnsZoneSync
            },
            {
              path: 'accounting-export',
              name: 'AccountingExport',
              component: AccountingExport
            },
            {
              path: 'wednesday-deal',
              name: 'WednesdayDeal',
              component: WednesdayDeal
            },
            {
              path: 'manage-customer',
              name: 'ManageCustomer',
              component: ManageCustomer
            },
            {
              path: 'manage-payment-method',
              name: 'ManagePaymentMethod',
              component: ManagePaymentMethod
            },
            {
              path: 'price-change',
              name: 'PriceChange',
              component: PriceChange
            }
          ]
        },
      ]
    }
  ]
},
{
  path: "/:catchAll(.*)",
  component: {
    template: "<router-view></router-view>"
  },
  beforeEnter: (to, from, next) => {
    next(redirectDefaultLanguage())
  }
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    if (to.hash) {
      return { el: to.hash }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  // lazy loading localized messages
  let newLocale = to.params.locale;
  let prevLocale = from.params.locale;
  let fullPath = to.fullPath;

  if (typeof prevLocale === 'undefined') {
    if(checkAuth()) {
      // Only reroute logged in users since the language is part of the master data and
      // must be changed via GUI.
      const lang = localStorage.getItem("language");
      if (lang != null) {
        setLocale(lang.toLowerCase());
        to.fullPath = to.fullPath.replace(/\/en$/, '/' + lang.toLowerCase());
        to.fullPath = to.fullPath.replace(/\/de$/, '/' + lang.toLowerCase());
        to.fullPath = to.fullPath.replace(/\/en\//, '/' + lang.toLowerCase() + '/');
        to.fullPath = to.fullPath.replace(/\/de\//, '/' + lang.toLowerCase() + '/');
        to.path = to.path.replace(/\/en$/, '/' + lang.toLowerCase());
        to.path = to.path.replace(/\/de$/, '/' + lang.toLowerCase());
        to.path = to.path.replace(/\/en\//, '/' + lang.toLowerCase() + '/');
        to.path = to.path.replace(/\/de\//, '/' + lang.toLowerCase() + '/');
        to.params.locale = lang.toLowerCase();
        prevLocale = lang.toLowerCase();
        newLocale = lang.toLowerCase();
        await i18n.loadMessages(newLocale, fullPath)
        i18n.setLocale(newLocale)
      }
    } else {
      if(!locales.includes(to.params.locale)) {
        next(redirectDefaultLanguage())
      } else {
        setLocale(to.params.locale);
        await i18n.loadMessages(to.params.locale, fullPath)
        i18n.setLocale(to.params.locale)
      }
    }
  }

  if (newLocale !== prevLocale) {
    if(!locales.includes(to.params.locale)) {
      next(redirectDefaultLanguage())
    } else {
      await i18n.loadMessages(newLocale, fullPath)
      i18n.setLocale(newLocale)
    }
  }
  
  if (fullPath.includes('/tld/') || fullPath.includes('/terms-and-conditions') || fullPath.includes('/privacy-policy') || fullPath.includes('/promotions')) {
    await i18n.loadMessages(to.params.locale, fullPath)
  }

  // redirect from www to non www
  //if (window.location.host.split('.')[0] == 'www' && window.location.host.split('.')[1] != 'stg')
  //  window.location.href = "https://manotori.com/";

  let locale = to.params.locale;
  document.querySelector('html').setAttribute('lang', locale);

  /*
  let nearestWithTitle;
  if (locale == 'de') {
    nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.de.title);
    if (nearestWithTitle)
      document.title = nearestWithTitle.meta.de.title + ' | manotori';
  } else {
    nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.en.title);
    if (nearestWithTitle)
      document.title = nearestWithTitle.meta.en.title + ' | manotori';
  }
  */

  let nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta[locale] && r.meta[locale].metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta[locale] && r.meta[locale].metaTags);

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta)
    return next();

  const metaTags = nearestWithMeta.meta[locale].metaTags;

  metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      if (to.params.tld) {
        // set tld in meta desc for tld info pages
        tag.setAttribute(key, tagDef[key].replace('{tld}', '.' + to.params.tld.toUpperCase()));
      } else {
        tag.setAttribute(key, tagDef[key]);
      }
    });

    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
  .forEach(tag => document.head.appendChild(tag));

  let skipHreflang = false;
  if ((/[A-Z]/.test(to.fullPath)) || (/\/+$/.test(to.fullPath))) {
    // URL contains upper case characters or trailing slashes and doesn't look like expected.
    // hreflang should not be used on pages that use a canonical tag pointing to a different page!
    skipHreflang = true;
  }

  // Test: don't create href lang tags for .SPACE
  if ((/space/.test(to.fullPath))) {
    skipHreflang = true;
  }

  // hreflang de & en
  if (!skipHreflang) {
    locales.forEach(lang => {
      const linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'alternate');
      linkTag.setAttribute('hreflang', lang);
      let url = nearestWithMeta.path.replace('/:locale', '/' + lang + '');
      if (typeof(to.params.tld) != "undefined") {
        url = url.replace('/:tld-domain', '/' + to.params.tld.toLowerCase() + '-domain');
      }
      linkTag.setAttribute('href', 'https://manotori.com' + url);
      linkTag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(linkTag);
    });
  }

  // hreflang x-default
  if (!skipHreflang) {
    // Add x-default language switch for /, /de and /en pages...
    if (to.fullPath == '/' || to.fullPath == '/de' || to.fullPath == '/en') {
      const linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'alternate');
      linkTag.setAttribute('hreflang', 'x-default');
      linkTag.setAttribute('href', 'https://manotori.com');
      linkTag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(linkTag);
    } else {
      // ...and add x-default language fallback for all other pages.
      const linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'alternate');
      linkTag.setAttribute('hreflang', 'x-default');
      let url = nearestWithMeta.path.replace('/:locale', '/en');
      if (typeof(to.params.tld) != "undefined") {
        url = url.replace('/:tld-domain', '/' + to.params.tld.toLowerCase() + '-domain');
      }
      linkTag.setAttribute('href', 'https://manotori.com' + url);
      linkTag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(linkTag);
    }
  }

  // add canonical information for all pages except for /, /de and /en pages
  if (to.fullPath != '/' && to.fullPath != '/de' && to.fullPath != '/en') {
    const linkTag = document.createElement('link');
    linkTag.setAttribute('rel', 'canonical');
    // always use lower case characters and remove trailing slashes
    linkTag.setAttribute('href', 'https://manotori.com' + to.fullPath.toLowerCase().replace(/\/+$/, ''));
    linkTag.setAttribute('data-vue-router-controlled', '');
    document.head.appendChild(linkTag);
  }

  // add thumbnail information for all TLD info pages
  if ((/.*\/tld\/.*/.test(to.fullPath))) {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'thumbnail');
    metaTag.setAttribute('content', 'https://manotori.com/img/tld_info_thumbnails/' + to.params.tld.toLowerCase() + '.png');
    metaTag.setAttribute('data-vue-router-controlled', '');
    document.head.appendChild(metaTag);
  }

  next();
});

export default router
