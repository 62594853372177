<template>

  <a
    href="#"
    class="close-navbar-toggler collapsed"
    data-toggle="collapse"
    data-target="#navbarContent"
    aria-controls="navbarContent"
    aria-expanded="false"
    aria-label="Toggle navigation">
  </a>
  
  <div>
    <nav
      class="navbar navbar-dark fixed-top navbar-expand-xl"
      :class="{
        menubar: scrollPosition > 30,
        headerSolid: scrollPosition < 30 && $route.path != '/en' && $route.path != '/de' && $route.path != '/en/' && $route.path != '/de/'
      }"
    >
      <router-link
        :to="{path: `/${this.$root.$i18n.locale}`}"
        class="navbar-brand"
        data-toggle="collapse" data-target=".navbar-collapse.show"
      >
        <img style="transform: scale(0.9);" src="/img/homepage/manotori_logo.svg" width="150" height="23" alt="manotori"/>
      </router-link>

      <div class="ml-auto">
        <router-link
          class="d-none d-block d-xl-none"
          :to="{path: `/${this.$root.$i18n.locale}${(checkAuthUser()?'/s':'')}/shopping-cart`}"
        >
          <div class="pl-2 pr-3" style="padding-bottom: 3px;">
            <img src="/img/action_icons/shopping_cart.svg" :class="{'shake': shakeCart}" width="21" height="21" :alt="$t('shoppingCart')" />
            <span v-if="shoppingCartItemsCount == 0" :class="{'shake': shakeCart}" class="badge" id="item-count-mobile-neutral">{{ shoppingCartItemsCount }}</span>
            <span v-if="shoppingCartItemsCount != 0" :class="{'shake': shakeCart}" class="badge" id="item-count-mobile">{{ shoppingCartItemsCount }}</span>
          </div>
        </router-link>
      </div>

      <div class="">
        <router-link
          class="d-none d-block d-xl-none"
          :to="{path: `/${this.$root.$i18n.locale}${(checkAuthUser()?'/s':'')}/watch-list`}"
        >
          <div class="pl-2 pr-4" style="padding-bottom: 3px;">
            <img src="/img/action_icons/shopping_list.svg" :class="{'shake': shakeList}" width="21" height="21" :alt="$t('shoppingList')" />
            <span v-if="shoppingListItemsCount == 0" :class="{'shake': shakeList}" class="badge" id="item-count-mobile-neutral">{{ shoppingListItemsCount }}</span>
            <span v-if="shoppingListItemsCount != 0" :class="{'shake': shakeList}" class="badge" id="item-count-mobile">{{ shoppingListItemsCount }}</span>
          </div>
        </router-link>
      </div>

      <button
        type="button"
        class="navbar-toggler collapsed"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarContent" class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto header-nav-link">

          <li class="nav-item dropdown">
            <a
              class="navbar-text dropdown-toggle"
              href="#"
              id="navbarDropdownDomain"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("domains") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownDomain">

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/register-domain`}"
              >
                <span class="item-padding">
                  {{ $t("domainRegistration") }}
                </span>
              </router-link>

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/transfer-domain`}"
              >
                <span class="item-padding">
                  {{ $t("domainTransfer") }}
                </span>
              </router-link>

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/domains`}"
              >
                <span class="item-padding">
                  {{ $t("tldOverview") }}
                </span>
              </router-link>

            </div>
          </li>

          <li>
            <router-link
              class="navbar-text"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}/features`}"
            >
              {{ $t("usability") }}
            </router-link>
          </li>

          <li v-if="checkAuthUser()" class="nav-item dropdown">
            <a
              class="navbar-text dropdown-toggle"
              href="#"
              id="navbarDropdownDomainManagement"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("domainManagement") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownDomainManagement">

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/s/domain-overview`}"
              >
                <span class="item-padding">
                  {{ $t("domainList") }}
                </span>
              </router-link>

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/s/domain-contacts`}"
              >
                <span class="item-padding">
                  {{ $t("domainContacts") }}
                </span>
              </router-link>

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/s/nameserver-sets`}"
              >
                <span class="item-padding">
                  {{ $t("nameserverSet") }}
                </span>
              </router-link>

              <div v-if="!this.isStandardMode()">
                <router-link
                  class="dropdown-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  :to="{path: `/${this.$root.$i18n.locale}/s/dns-zones`}"
                >
                  <span class="item-padding">
                    {{ $t("dnsZones") }}
                  </span>
                </router-link>
              </div>

              <router-link
                class="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                :to="{path: `/${this.$root.$i18n.locale}/s/open-orders`}"
              >
                <span class="item-padding">
                  {{ $t("openOrders") }}
                  <span v-if="prepaymentOrderCount != 0" class="badge" id="item-count-menu">{{ prepaymentOrderCount }}</span>
                </span>
              </router-link>
            </div>
          </li>

          <li>
            <router-link
              class="navbar-text"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}/prices`,}"
            >
              {{ $t("prices_name") }}
            </router-link>
          </li>

          <li>
            <a
              class="navbar-text"
              :href="this.getHelpSystemUrl() + '/?lang=' + this.$root.$i18n.locale"
              target="_blank"
              rel="noopener"
            >
              {{ $t("help") }}
            </a>
          </li>

          <li>
            <router-link
              class="navbar-my-account navbar-icon d-none d-xl-block"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}/${(checkAuthUser()?'s/dashboard':'login')}`}"
            >
              <button type="button" class="btn index-button btn-primary my-account-btn">
                {{ $t("myAccount") }}
              </button>
            </router-link>
            <router-link
              class="navbar-text d-none d-block d-xl-none"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}/${(checkAuthUser()?'s/dashboard':'login')}`}"
            >
              <img class="pr-2 pb-1" src="/img/action_icons/my_account.svg" :alt="$t('myAccount')"/> {{ $t("myAccount") }}
            </router-link>
          </li>

          <li>
            <router-link
              class="navbar-icon d-none d-xl-block"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}${(checkAuthUser()?'/s':'')}/shopping-cart`}"
            >
              <img src="/img/action_icons/shopping_cart.svg" :class="{'shake': shakeCart}" width="21" height="21" :alt="$t('shoppingCart')" />
              <span v-if="shoppingCartItemsCount == 0" :class="{'shake': shakeCart}" class="badge" id="item-count-neutral">{{ shoppingCartItemsCount }}</span>
              <span v-if="shoppingCartItemsCount != 0" :class="{'shake': shakeCart}" class="badge" id="item-count">{{ shoppingCartItemsCount }}</span>
            </router-link>
          </li>

          <li>
            <router-link
              class="navbar-icon d-none d-xl-block"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              :to="{path: `/${this.$root.$i18n.locale}${(checkAuthUser()?'/s':'')}/watch-list`}"
            >
              <img src="/img/action_icons/shopping_list.svg" :class="{'shake': shakeList}" width="21" height="21" :alt="$t('shoppingList')" />
              <span v-if="shoppingListItemsCount == 0" :class="{'shake': shakeList}" class="badge" id="item-count-neutral">{{ shoppingListItemsCount }}</span>
              <span v-if="shoppingListItemsCount != 0" :class="{'shake': shakeList}" class="badge" id="item-count">{{ shoppingListItemsCount }}</span>
            </router-link>
          </li>

          <li
            class="navbar-plain-text-ul-left"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            style="text-decoration: underline;"
            role="button"
            tabindex="0"
            @click="showSelectionModal=true"
          >
            {{displaySelectedLanguage}}
          </li>
          <li class="navbar-plain-text d-none d-xl-block">&nbsp;|&nbsp;</li>
          <li
            class="navbar-plain-text-ul-right"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            style="text-decoration: underline;"
            role="button"
            tabindex="0"
            @click="showSelectionModal=true"
            v-if="vatDisplay !== false"
          >
            {{ vatDisplay }} % {{ $t("vat") }}
          </li>

          <li v-if="checkAuthUser()">
            <div class="navbar-icon d-none d-xl-block" data-toggle="collapse" data-target=".navbar-collapse.show">
              <img
                @click="logoutSessionClick"
                class="pl-3 pr-3 pb-1"
                src="/img/action_icons/logout.svg"
                :alt="$t('logout')"
              />
            </div>
            <div class="navbar-text d-none d-block d-xl-none" style="cursor: pointer;" data-toggle="collapse" data-target=".navbar-collapse.show">
              <div @click="logoutSessionClick">
                <img
                  class="pr-2 pb-1"
                  src="/img/action_icons/logout.svg"
                  :alt="$t('logout')"
                />
                {{ $t("logout") }}
              </div>
            </div>
          </li>

        </ul>
      </div>
    </nav>
    <SelectionModal
      :show-modal-header="showSelectionModal"
      @set-show-selection-modal="setShowSelectionModal()"
    />
  </div>
</template>

<script>
import HttpMixin from '@/mixins/http.mixin'
import SelectionModal from '@/components/homepage/SelectionModal'

export default {
  name: "AppHeader",
  mixins: [HttpMixin],
  components: {SelectionModal},
  data() {
    return {
      scrollPosition: null,
      showSelectionModal: false,
      shakeCart: false,
      shakeList: false,
      timer: null
    };
  },
  computed: {
    shoppingCartItemsCount() {
      return this.$store.state.shoppingCartCount
    },
    shoppingListItemsCount() {
      return this.$store.state.shoppingListCount
    },
    prepaymentOrderCount() {
      return this.$store.state.prepaymentOrderCount
    },
    displaySelectedLanguage() {
      if (this.$route.params.locale == 'de') {
        return 'DE'
      } else if (this.$route.params.locale == 'en') {
        return 'EN'
      } else {
        return ''
      }
    },
    vatDisplay() {
      if (this.$store.state.vatDisplay) {
        return this.$store.state.vatDisplay.vat
      }
      return false
    }
  },
  watch: {
    shoppingCartItemsCount: function(newValue, oldValue) {
      if (newValue > oldValue) {
        this.shakeCart = true;
      }
    },
    shoppingListItemsCount: function(newValue, oldValue) {
      if (newValue > oldValue) {
        this.shakeList = true;
      }
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    selectLanguage(language) {
      if (language == "de") {
        this.$root.$i18n.locale = "de";
      } else {
        this.$root.$i18n.locale = "en";
      }
      this.$router.push({ path: "/" + this.$i18n.locale });
    },
    async logoutSessionClick() {
      this.logoutSession()
    },
    setShowSelectionModal() {
      this.showSelectionModal = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);

    this.timer = setInterval(() => {
      this.shakeCart = false;
      this.shakeList = false;
      clearInterval();
    }, 1000);
  },
};
</script>
