<template>
  <app-header></app-header>
  <main
    :class="{
      'main-content':
        $route.fullPath == '/de' || $route.fullPath == '/en' ||
        $route.fullPath == '/de/' || $route.fullPath == '/en/' ||
        $route.fullPath == '/de/features' || $route.fullPath == '/de/features/' ||
        $route.fullPath == '/en/features' || $route.fullPath == '/en/features/' ? false : true,
      }"
    >
    <router-view />
  </main>
  <app-footer id="footer"></app-footer>
</template>

<script>
import HttpMixin from "@/mixins/http.mixin";
import { checkAuth } from "@/router/auth.js";

import AppHeader from "@/components/homepage/AppHeader";
import AppFooter from "@/components/homepage/AppFooter";

export default {
  name: "App",
  mixins: [HttpMixin],
  components: {
    AppHeader,
    AppFooter,
  },
  async created() {
    if (this.$store.state.shoppingCartCount === null && checkAuth()) {
      const shoppingCartStats = await this.req('GET', '/v1/shoppingCart/statistics');
      this.$store.commit('shoppingCartCountReset', shoppingCartStats.data.data.shopping_cart_item_count);
      this.$store.commit('shoppingListCountReset', shoppingCartStats.data.data.shopping_list_item_count);
      this.$store.commit('prepaymentOrderCountReset', shoppingCartStats.data.data.prepayment_order_count);
    }
  },
  mounted() {
    // dirty solution to fix the "Cumulative Layout Shift" issue
    // -> hide footer
    document.getElementById("footer").style.display = "none";
  },
  updated() {
    if (checkAuth()) {
      let logoutInterval = setInterval(async () => {
        if (!checkAuth()) {
          await this.forceLogoutSession();
          clearInterval(logoutInterval);
        }
      }, 2000);
    }
    // dirty solution to fix the "Cumulative Layout Shift" issue
    // -> show footer
    document.getElementById("footer").style.display = "block";
  }
};
</script>
