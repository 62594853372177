<template>
  <vue-title v-if="this.$route.name == 'register-domain'" :title="$t('registerDomainTitle')"></vue-title>
  <vue-title v-if="this.$route.name == 'transfer-domain'" :title="$t('transferDomainTitle')"></vue-title>
  <vue-title v-if="this.$route.name == 'check-domain'" :title="$t('checkDomainTitle')"></vue-title>

  <div id="header-area-ds">
    <div id="header-content">
      <div class="header-search">
        <div class="domain-search">
          <h4 v-if="searchMode == 'REGISTRATION'" class="text-center pb-4">{{$t('domainSearch_registerText')}}</h4>
          <h4 v-if="searchMode == 'TRANSFER'" class="text-center pb-4">{{$t('domainSearch_transferText')}}</h4>

          <div class="d-flex justify-content-center">
            <Form @submit="searchDomain" @submit.prevent class="form-inline" style="flex-flow: row;">
              <label class="sr-only" for="domain-name">{{ $t("searchDomain") }}</label>
              <input
                v-model="domainName"
                type="text"
                id="domain-name"
                class="search-input"
                minlength="1"
                maxlength="63"
                :placeholder="$t('searchDomain')"
              />
              <button class="btn search-button btn-primary" type="submit">
                <img src="/img/homepage/search.svg" />
              </button>
            </Form>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="wrapper" style="margin-top: 0px;">
    <div v-if="!isDomainSearchActive" class="domain-search background-box">
      <div class="steps">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="card-template">
              <div class="card-wrapper" style="padding-top: 24px;">

                <div class="row">
                  <div class="mt-3 col-lg-4 col-12 step-help">
                    <div class="step-card">
                      <div class="step-number">1</div>
                      <div class="img-wrapper">
                        <img src="/img/domain_search/domain_search_s1.svg">
                      </div>
                      <p v-if="searchMode == 'REGISTRATION'">{{$t('domainSearch_step1.1')}}</p>
                      <p v-if="searchMode == 'TRANSFER'">{{$t('domainSearch_step1.2')}}</p>
                    </div>
                  </div>
                  <div class="mt-3 col-lg-4 col-12 step-help">
                    <div class="step-card">
                      <div class="step-number">2</div>
                      <div class="img-wrapper">
                        <img src="/img/domain_search/domain_search_s2.svg">
                      </div>
                      <p>{{$t('domainSearch_step2')}}</p>
                    </div>
                  </div>
                  <div class="mt-3 col-lg-4 col-12 step-help">
                    <div class="step-card">
                      <div class="step-number">3</div>
                      <div class="img-wrapper">
                        <img src="/img/domain_search/domain_search_s3.svg">
                      </div>
                      <p>{{$t('domainSearch_step3')}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDomainSearchActive" class="background-box domain-search">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-3 order-1">
          <div class="content-wrapper top-spacing bottom-spacing">

            <h5>{{ $t("domainSearch_general") }}</h5>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('mostRelevantDomains')" name="mostRelevantDomains" :checkedValue="true" :label="$t('domainSearch_mostRelevantDomains')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('promotions')" name="promotions" :checkedValue="true" :label="$t('domainSearch_promotions')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('onlyAvailableDomains')" name="onlyAvailableDomains" :label="$t('domainSearch_onlyAvailableDomains')"/>

            <h5 class="pt-3">{{ $t("domainSearch_region") }}</h5>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('europe')" name="europe" :label="$t('domainSearch_europe')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('asia')" name="asia" :label="$t('domainSearch_asia')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('america')" name="america" :label="$t('domainSearch_america')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('africa')" name="africa" :label="$t('domainSearch_africa')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('australiaAndOceania')" name="australiaAndOceania" :label="$t('domainSearch_australiaAndOceania')"/>

            <h5 class="pt-3">{{ $t("domainSearch_tldType") }}</h5>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('gTlds')" name="gTlds" :label="$t('domainSearch_gTlds')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('ccTlds')" name="ccTlds" :label="$t('domainSearch_ccTlds')"/>
            <Checkbox :isStandardStyle="false" class="search-item" @change="searchDomain('newTlds')" name="newTlds" :label="$t('domainSearch_newTlds')"/>

          </div>

          <div class="content-wrapper mt-4 mb-4 top-spacing bottom-spacing">
            <h5>{{ $t("domainSearch_howItWorks") }}</h5>
            <div class="row">
              <div class="col-1 col-sm-1 col-md-2 col-lg-2">
                <img class="how-it-works-icons" src="/img/action_icons/shopping_cart_add.svg"/>
              </div>
              <div class="col-11 col-sm-11 col-md-10 col-lg-10">
                <p class="how-it-works-item">{{ $t('domainSearch_howItWorksStep1') }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-1 col-sm-1 col-md-2 col-lg-2">
                <img class="how-it-works-icons" src="/img/action_icons/shopping_list_add.svg"/>
              </div>
              <div class="col-11 col-sm-11 col-md-10 col-lg-10">
                <p class="how-it-works-item">{{ $t('domainSearch_howItWorksStep2') }}</p>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-9 order-md-2 mb-4">
          <div class="content-wrapper top-spacing bottom-spacing">

            <h5 class="pb-2">{{ $t("domainSearch_searchResult") }}</h5>
            
            <div v-for="(status, tld) in searchResult" :key="tld">
              <div v-if="!this.isTldUnsupported && (this.tld == null || tld == this.tld)" class="row search-result-line">
                <div class="col-6 col-sm-6 col-md-6 col-lg-8">
                  {{ domain }}.<router-link class="text-black" :to="{path: `/${this.$root.$i18n.locale}/tld/${tld.toLowerCase()}-domain`}" target="_blank">{{ tld.toUpperCase() }}</router-link>
                  <br/>
                  <div v-if="status == 'pending'" class="loading-spinner-xs"></div>
                  <div v-if="status == 'failed'" class="d-inline">
                    <span class="search-result-sub-line text-gray">{{ $t('domainSearch_checkNotPossible') }}</span>
                  </div>
                  <div v-if="status != 'pending' && status != 'failed'" class="d-inline">
                    <span class="search-result-sub-line text-green" v-if="status == true">{{ $t('domainSearch_available') }}</span>
                    <span class="search-result-sub-line text-blue" v-if="status == false">{{ $t('domainSearch_notAvailable') }}</span>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center text-right">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="status == true">{{ tldRegistrationPrices[tld] }}</div>
                      <div v-if="status == false">{{ tldTransferPrices[tld] }}</div>
                    </div>
                    <div class="col-12">
                      <div class="search-result-sub-line" v-if="status == true">{{ $t('domainSearch_' + tldRegistrationInclPeriod[tld]) }}</div>
                      <div class="search-result-sub-line" v-if="status == false">{{ $t('domainSearch_' + tldTransferInclPeriod[tld]) }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                  <img
                    v-if="status != 'pending' && status != 'failed'"
                    @click="addToShoppingCart(domain + '.' + tld.toUpperCase(), status, true)"
                    class="action-icon action-icon-padding"
                    data-toggle="tooltip"
                    :title="$t('domainSearch_shoppingList')"
                    :alt="$t('domainSearch_shoppingList')"
                    src="/img/action_icons/shopping_list_add.svg"
                  />
                  <img
                    v-if="status != 'pending' && status != 'failed'"
                    @click="addToShoppingCart(domain + '.' + tld.toUpperCase(), status)"
                    class="action-icon action-icon-padding"
                    data-toggle="tooltip"
                    :title="$t('domainSearch_shoppingCart')"
                    :alt="$t('domainSearch_shoppingCart')"
                    src="/img/action_icons/shopping_cart_add.svg"
                  />
                  <img v-if="status == 'pending'" class="action-icon-inactive" src="/img/action_icons/shopping_list_add.svg"/>
                  <img v-if="status == 'pending'" class="action-icon-inactive" src="/img/action_icons/shopping_cart_add.svg"/>
                </div>
              </div>
            </div>

            <div v-if="isTldUnsupported">
              <div v-if="tld == this.tld" class="row search-result-line">
                <div class="col-6 col-sm-6 col-md-6 col-lg-8">
                  {{ domain }}.{{ tld.toUpperCase() }}
                  <br/>
                  <div class="d-inline">
                    <span class="search-result-sub-line text-gray">{{ $t('domainSearch_tldUnavailable') }}</span>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center text-right">
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                  <img class="action-icon-inactive" src="/img/action_icons/shopping_list_add.svg"/>
                  <img class="action-icon-inactive" src="/img/action_icons/shopping_cart_add.svg"/>
                </div>
              </div>
            </div>            

            <div v-if="this.tld == null || tld == this.tld" class="row mt-4">
              <div class="col-12">
                <router-link :to="{path: `/${this.$root.$i18n.locale}/s/shopping-cart`,}">
                  <button class="btn btn-primary index-button float-right">{{ $t("domainSearch_goToShoppingCart") }}</button>
                </router-link>
              </div>
            </div>

            <h5 v-if="this.tld != null" class="pb-2 pt-4">{{ $t("domainSearch_searchResultAlternatives") }}</h5>
            <div v-for="(status, tld) in searchResult" :key="tld">
              <div v-if="this.tld != null && tld != this.tld" class="row search-result-line">
                <div class="col-6 col-sm-6 col-md-6 col-lg-8">
                  {{ domain }}.<router-link class="text-black" :to="{path: `/${this.$root.$i18n.locale}/tld/${tld.toLowerCase()}-domain`}" target="_blank">{{ tld.toUpperCase() }}</router-link><br/>
                  <div v-if="status == 'pending'" class="loading-spinner-xs"></div>
                  <div v-if="status == 'failed'" class="d-inline">
                    <span class="search-result-sub-line text-gray">{{ $t('domainSearch_checkNotPossible') }}</span>
                  </div>
                  <div v-if="status != 'pending' && status != 'failed'" class="d-inline">
                    <span class="search-result-sub-line text-green" v-if="status == true">{{ $t('domainSearch_available') }}</span>
                    <span class="search-result-sub-line text-blue" v-if="status == false">{{ $t('domainSearch_notAvailable') }}</span>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center text-right">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="status == true">{{ tldRegistrationPrices[tld] }}</div>
                      <div v-if="status == false">{{ tldTransferPrices[tld] }}</div>
                    </div>
                    <div class="col-12">
                      <div class="search-result-sub-line" v-if="status == true">{{ $t('domainSearch_' + tldRegistrationInclPeriod[tld]) }}</div>
                      <div class="search-result-sub-line" v-if="status == false">{{ $t('domainSearch_' + tldTransferInclPeriod[tld]) }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                  <img
                    v-if="status != 'pending' && status != 'failed'"
                    @click="addToShoppingCart(domain + '.' + tld.toUpperCase(), status, true)"
                    class="action-icon"
                    data-toggle="tooltip"
                    :title="$t('domainSearch_shoppingList')"
                    :alt="$t('domainSearch_shoppingList')"
                    src="/img/action_icons/shopping_list_add.svg"
                  />
                  <img
                    v-if="status != 'pending' && status != 'failed'"
                    @click="addToShoppingCart(domain + '.' + tld.toUpperCase(), status)"
                    class="action-icon"
                    data-toggle="tooltip"
                    :title="$t('domainSearch_shoppingCart')"
                    :alt="$t('domainSearch_shoppingCart')"
                    src="/img/action_icons/shopping_cart_add.svg"
                  />
                  <img v-if="status == 'pending'" class="action-icon-inactive" src="/img/action_icons/shopping_list_add.svg"/>
                  <img v-if="status == 'pending'" class="action-icon-inactive" src="/img/action_icons/shopping_cart_add.svg"/>
                </div>
              </div>
            </div>

            <div v-if="this.tld != null" class="row mt-4">
              <div class="col ">
                <router-link :to="{path: `/${this.$root.$i18n.locale}/s/shopping-cart`,}">
                  <button class="btn btn-primary index-button float-right">{{ $t("domainSearch_goToShoppingCart") }}</button>
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <transition v-if="isErrorMsgModalOpen" v-body-scroll-lock="isErrorMsgModalOpen" name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-dialog-scrollable" role="document" style="max-width: 550px;">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="bg-gray mt-1 pt-3 pb-3 pl-4 pr-4">

                <div v-if="!isShoppingCart" class="row">

                  <div class="col-12">
                    <span v-if="errorType == 436">
                      <p>{{ $t('domainSearch_436') }}</p>
                      <router-link :to="{path: `/${this.$root.$i18n.locale}/s/shopping-cart`,}">{{ $t('domainSearch_436_url') }}</router-link>
                    </span>
                    <span v-else-if="errorType == 437">
                      <p>{{ $t('domainSearch_437') }}</p>
                      <router-link :to="{path: `/${this.$root.$i18n.locale}/s/shopping-cart`,}">{{ $t('domainSearch_437_url') }}</router-link>
                    </span>
                    <span v-else-if="errorType == 438">
                      <p>{{ $t('domainSearch_438') }}</p>
                      <router-link :to="{path: `/${this.$root.$i18n.locale}/s/watch-list`,}">{{ $t('domainSearch_438_url') }}</router-link>
                    </span>
                    <span v-else-if="errorType == 439">
                      <p>{{ $t('domainSearch_439') }}</p>
                      <router-link :to="{path: `/${this.$root.$i18n.locale}/s/open-orders`,}">{{ $t('domainSearch_439_url') }}</router-link>
                    </span>
                    <span v-else-if="errorType == 412">{{ $t('domainSearch_invalidCharacters') }}</span>
                    <span v-else>{{ $t('domainSearch_actionNotPossible') }}</span>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                </div>
                <div class="col-4">  
                  <button
                    class="w-100 btn index-button btn-primary"
                    @click="isErrorMsgModalOpen = false; errorType = null;"
                  >
                    {{$t('okay')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import HttpMixin from '@/mixins/http.mixin'
import Checkbox from "@/components/input_controls/Checkbox";
import { mapState } from "vuex";

export default {
  name: 'DomainSearch',
  mixins: [HttpMixin],
  components: {Checkbox},
  data: function() {
    return {
      connection: null,
      isDomainSearchActive: false,
      searchResult: {},
      domain: null,
      tld: null,
      useRegionEurope: false,
      useRegionAsia: false,
      useRegionAmerica: false,
      useRegionAfrica: false,
      useRegionAustraliaOceania: false,
      useTypeGtlds: false,
      useTypeCctlds: false,
      useTypeNtlds: false,
      useMostRelevant: true,
      useSpecialOffer: true,
      useOnlyAvailableDomains: false,
      typeGtlds: null,
      typeNtlds: null,
      typeCctlds: null,
      regionEurope: false,
      regionAsia: false,
      regionAmerica: false,
      regionAfrica: false,
      regionAustraliaOceania: false,
      mostRelevant: false,
      specialOffer: false,
      isTldDataLoaded: false,
      tldRegistrationPrices: {},
      tldRegistrationInclPeriod: {},
      tldTransferPrices: {},
      tldTransferInclPeriod: {},
      isErrorMsgModalOpen: false,
      errorType: null,
      isOverrideActive: false,
      isTldUnsupported: false,
      // Version 2024071400, Last Updated Sun Jul 14 07:07:02 2024 UTC
      tldList: [
        "AAA", 
        "AARP", 
        "ABB", 
        "ABBOTT", 
        "ABBVIE", 
        "ABC", 
        "ABLE", 
        "ABOGADO", 
        "ABUDHABI", 
        "AC", 
        "ACADEMY", 
        "ACCENTURE", 
        "ACCOUNTANT", 
        "ACCOUNTANTS", 
        "ACO", 
        "ACTOR", 
        "AD", 
        "ADS", 
        "ADULT", 
        "AE", 
        "AEG", 
        "AERO", 
        "AETNA", 
        "AF", 
        "AFL", 
        "AFRICA", 
        "AG", 
        "AGAKHAN", 
        "AGENCY", 
        "AI", 
        "AIG", 
        "AIRBUS", 
        "AIRFORCE", 
        "AIRTEL", 
        "AKDN", 
        "AL", 
        "ALIBABA", 
        "ALIPAY", 
        "ALLFINANZ", 
        "ALLSTATE", 
        "ALLY", 
        "ALSACE", 
        "ALSTOM", 
        "AM", 
        "AMAZON", 
        "AMERICANEXPRESS", 
        "AMERICANFAMILY", 
        "AMEX", 
        "AMFAM", 
        "AMICA", 
        "AMSTERDAM", 
        "ANALYTICS", 
        "ANDROID", 
        "ANQUAN", 
        "ANZ", 
        "AO", 
        "AOL", 
        "APARTMENTS", 
        "APP", 
        "APPLE", 
        "AQ", 
        "AQUARELLE", 
        "AR", 
        "ARAB", 
        "ARAMCO", 
        "ARCHI", 
        "ARMY", 
        "ARPA", 
        "ART", 
        "ARTE", 
        "AS", 
        "ASDA", 
        "ASIA", 
        "ASSOCIATES", 
        "AT", 
        "ATHLETA", 
        "ATTORNEY", 
        "AU", 
        "AUCTION", 
        "AUDI", 
        "AUDIBLE", 
        "AUDIO", 
        "AUSPOST", 
        "AUTHOR", 
        "AUTO", 
        "AUTOS", 
        "AW", 
        "AWS", 
        "AX", 
        "AXA", 
        "AZ", 
        "AZURE", 
        "BA", 
        "BABY", 
        "BAIDU", 
        "BANAMEX", 
        "BAND", 
        "BANK", 
        "BAR", 
        "BARCELONA", 
        "BARCLAYCARD", 
        "BARCLAYS", 
        "BAREFOOT", 
        "BARGAINS", 
        "BASEBALL", 
        "BASKETBALL", 
        "BAUHAUS", 
        "BAYERN", 
        "BB", 
        "BBC", 
        "BBT", 
        "BBVA", 
        "BCG", 
        "BCN", 
        "BD", 
        "BE", 
        "BEATS", 
        "BEAUTY", 
        "BEER", 
        "BENTLEY", 
        "BERLIN", 
        "BEST", 
        "BESTBUY", 
        "BET", 
        "BF", 
        "BG", 
        "BH", 
        "BHARTI", 
        "BI", 
        "BIBLE", 
        "BID", 
        "BIKE", 
        "BING", 
        "BINGO", 
        "BIO", 
        "BIZ", 
        "BJ", 
        "BLACK", 
        "BLACKFRIDAY", 
        "BLOCKBUSTER", 
        "BLOG", 
        "BLOOMBERG", 
        "BLUE", 
        "BM", 
        "BMS", 
        "BMW", 
        "BN", 
        "BNPPARIBAS", 
        "BO", 
        "BOATS", 
        "BOEHRINGER", 
        "BOFA", 
        "BOM", 
        "BOND", 
        "BOO", 
        "BOOK", 
        "BOOKING", 
        "BOSCH", 
        "BOSTIK", 
        "BOSTON", 
        "BOT", 
        "BOUTIQUE", 
        "BOX", 
        "BR", 
        "BRADESCO", 
        "BRIDGESTONE", 
        "BROADWAY", 
        "BROKER", 
        "BROTHER", 
        "BRUSSELS", 
        "BS", 
        "BT", 
        "BUILD", 
        "BUILDERS", 
        "BUSINESS", 
        "BUY", 
        "BUZZ", 
        "BV", 
        "BW", 
        "BY", 
        "BZ", 
        "BZH", 
        "CA", 
        "CAB", 
        "CAFE", 
        "CAL", 
        "CALL", 
        "CALVINKLEIN", 
        "CAM", 
        "CAMERA", 
        "CAMP", 
        "CANON", 
        "CAPETOWN", 
        "CAPITAL", 
        "CAPITALONE", 
        "CAR", 
        "CARAVAN", 
        "CARDS", 
        "CARE", 
        "CAREER", 
        "CAREERS", 
        "CARS", 
        "CASA", 
        "CASE", 
        "CASH", 
        "CASINO", 
        "CAT", 
        "CATERING", 
        "CATHOLIC", 
        "CBA", 
        "CBN", 
        "CBRE", 
        "CC", 
        "CD", 
        "CENTER", 
        "CEO", 
        "CERN", 
        "CF", 
        "CFA", 
        "CFD", 
        "CG", 
        "CH", 
        "CHANEL", 
        "CHANNEL", 
        "CHARITY", 
        "CHASE", 
        "CHAT", 
        "CHEAP", 
        "CHINTAI", 
        "CHRISTMAS", 
        "CHROME", 
        "CHURCH", 
        "CI", 
        "CIPRIANI", 
        "CIRCLE", 
        "CISCO", 
        "CITADEL", 
        "CITI", 
        "CITIC", 
        "CITY", 
        "CK", 
        "CL", 
        "CLAIMS", 
        "CLEANING", 
        "CLICK", 
        "CLINIC", 
        "CLINIQUE", 
        "CLOTHING", 
        "CLOUD", 
        "CLUB", 
        "CLUBMED", 
        "CM", 
        "CN", 
        "CO", 
        "COACH", 
        "CODES", 
        "COFFEE", 
        "COLLEGE", 
        "COLOGNE", 
        "COM", 
        "COMMBANK", 
        "COMMUNITY", 
        "COMPANY", 
        "COMPARE", 
        "COMPUTER", 
        "COMSEC", 
        "CONDOS", 
        "CONSTRUCTION", 
        "CONSULTING", 
        "CONTACT", 
        "CONTRACTORS", 
        "COOKING", 
        "COOL", 
        "COOP", 
        "CORSICA", 
        "COUNTRY", 
        "COUPON", 
        "COUPONS", 
        "COURSES", 
        "CPA", 
        "CR", 
        "CREDIT", 
        "CREDITCARD", 
        "CREDITUNION", 
        "CRICKET", 
        "CROWN", 
        "CRS", 
        "CRUISE", 
        "CRUISES", 
        "CU", 
        "CUISINELLA", 
        "CV", 
        "CW", 
        "CX", 
        "CY", 
        "CYMRU", 
        "CYOU", 
        "CZ", 
        "DABUR", 
        "DAD", 
        "DANCE", 
        "DATA", 
        "DATE", 
        "DATING", 
        "DATSUN", 
        "DAY", 
        "DCLK", 
        "DDS", 
        "DE", 
        "DEAL", 
        "DEALER", 
        "DEALS", 
        "DEGREE", 
        "DELIVERY", 
        "DELL", 
        "DELOITTE", 
        "DELTA", 
        "DEMOCRAT", 
        "DENTAL", 
        "DENTIST", 
        "DESI", 
        "DESIGN", 
        "DEV", 
        "DHL", 
        "DIAMONDS", 
        "DIET", 
        "DIGITAL", 
        "DIRECT", 
        "DIRECTORY", 
        "DISCOUNT", 
        "DISCOVER", 
        "DISH", 
        "DIY", 
        "DJ", 
        "DK", 
        "DM", 
        "DNP", 
        "DO", 
        "DOCS", 
        "DOCTOR", 
        "DOG", 
        "DOMAINS", 
        "DOT", 
        "DOWNLOAD", 
        "DRIVE", 
        "DTV", 
        "DUBAI", 
        "DUNLOP", 
        "DUPONT", 
        "DURBAN", 
        "DVAG", 
        "DVR", 
        "DZ", 
        "EARTH", 
        "EAT", 
        "EC", 
        "ECO", 
        "EDEKA", 
        "EDU", 
        "EDUCATION", 
        "EE", 
        "EG", 
        "EMAIL", 
        "EMERCK", 
        "ENERGY", 
        "ENGINEER", 
        "ENGINEERING", 
        "ENTERPRISES", 
        "EPSON", 
        "EQUIPMENT", 
        "ER", 
        "ERICSSON", 
        "ERNI", 
        "ES", 
        "ESQ", 
        "ESTATE", 
        "ET", 
        "EU", 
        "EUROVISION", 
        "EUS", 
        "EVENTS", 
        "EXCHANGE", 
        "EXPERT", 
        "EXPOSED", 
        "EXPRESS", 
        "EXTRASPACE", 
        "FAGE", 
        "FAIL", 
        "FAIRWINDS", 
        "FAITH", 
        "FAMILY", 
        "FAN", 
        "FANS", 
        "FARM", 
        "FARMERS", 
        "FASHION", 
        "FAST", 
        "FEDEX", 
        "FEEDBACK", 
        "FERRARI", 
        "FERRERO", 
        "FI", 
        "FIDELITY", 
        "FIDO", 
        "FILM", 
        "FINAL", 
        "FINANCE", 
        "FINANCIAL", 
        "FIRE", 
        "FIRESTONE", 
        "FIRMDALE", 
        "FISH", 
        "FISHING", 
        "FIT", 
        "FITNESS", 
        "FJ", 
        "FK", 
        "FLICKR", 
        "FLIGHTS", 
        "FLIR", 
        "FLORIST", 
        "FLOWERS", 
        "FLY", 
        "FM", 
        "FO", 
        "FOO", 
        "FOOD", 
        "FOOTBALL", 
        "FORD", 
        "FOREX", 
        "FORSALE", 
        "FORUM", 
        "FOUNDATION", 
        "FOX", 
        "FR", 
        "FREE", 
        "FRESENIUS", 
        "FRL", 
        "FROGANS", 
        "FRONTIER", 
        "FTR", 
        "FUJITSU", 
        "FUN", 
        "FUND", 
        "FURNITURE", 
        "FUTBOL", 
        "FYI", 
        "GA", 
        "GAL", 
        "GALLERY", 
        "GALLO", 
        "GALLUP", 
        "GAME", 
        "GAMES", 
        "GAP", 
        "GARDEN", 
        "GAY", 
        "GB", 
        "GBIZ", 
        "GD", 
        "GDN", 
        "GE", 
        "GEA", 
        "GENT", 
        "GENTING", 
        "GEORGE", 
        "GF", 
        "GG", 
        "GGEE", 
        "GH", 
        "GI", 
        "GIFT", 
        "GIFTS", 
        "GIVES", 
        "GIVING", 
        "GL", 
        "GLASS", 
        "GLE", 
        "GLOBAL", 
        "GLOBO", 
        "GM", 
        "GMAIL", 
        "GMBH", 
        "GMO", 
        "GMX", 
        "GN", 
        "GODADDY", 
        "GOLD", 
        "GOLDPOINT", 
        "GOLF", 
        "GOO", 
        "GOODYEAR", 
        "GOOG", 
        "GOOGLE", 
        "GOP", 
        "GOT", 
        "GOV", 
        "GP", 
        "GQ", 
        "GR", 
        "GRAINGER", 
        "GRAPHICS", 
        "GRATIS", 
        "GREEN", 
        "GRIPE", 
        "GROCERY", 
        "GROUP", 
        "GS", 
        "GT", 
        "GU", 
        "GUCCI", 
        "GUGE", 
        "GUIDE", 
        "GUITARS", 
        "GURU", 
        "GW", 
        "GY", 
        "HAIR", 
        "HAMBURG", 
        "HANGOUT", 
        "HAUS", 
        "HBO", 
        "HDFC", 
        "HDFCBANK", 
        "HEALTH", 
        "HEALTHCARE", 
        "HELP", 
        "HELSINKI", 
        "HERE", 
        "HERMES", 
        "HIPHOP", 
        "HISAMITSU", 
        "HITACHI", 
        "HIV", 
        "HK", 
        "HKT", 
        "HM", 
        "HN", 
        "HOCKEY", 
        "HOLDINGS", 
        "HOLIDAY", 
        "HOMEDEPOT", 
        "HOMEGOODS", 
        "HOMES", 
        "HOMESENSE", 
        "HONDA", 
        "HORSE", 
        "HOSPITAL", 
        "HOST", 
        "HOSTING", 
        "HOT", 
        "HOTELS", 
        "HOTMAIL", 
        "HOUSE", 
        "HOW", 
        "HR", 
        "HSBC", 
        "HT", 
        "HU", 
        "HUGHES", 
        "HYATT", 
        "HYUNDAI", 
        "IBM", 
        "ICBC", 
        "ICE", 
        "ICU", 
        "ID", 
        "IE", 
        "IEEE", 
        "IFM", 
        "IKANO", 
        "IL", 
        "IM", 
        "IMAMAT", 
        "IMDB", 
        "IMMO", 
        "IMMOBILIEN", 
        "IN", 
        "INC", 
        "INDUSTRIES", 
        "INFINITI", 
        "INFO", 
        "ING", 
        "INK", 
        "INSTITUTE", 
        "INSURANCE", 
        "INSURE", 
        "INT", 
        "INTERNATIONAL", 
        "INTUIT", 
        "INVESTMENTS", 
        "IO", 
        "IPIRANGA", 
        "IQ", 
        "IR", 
        "IRISH", 
        "IS", 
        "ISMAILI", 
        "IST", 
        "ISTANBUL", 
        "IT", 
        "ITAU", 
        "ITV", 
        "JAGUAR", 
        "JAVA", 
        "JCB", 
        "JE", 
        "JEEP", 
        "JETZT", 
        "JEWELRY", 
        "JIO", 
        "JLL", 
        "JM", 
        "JMP", 
        "JNJ", 
        "JO", 
        "JOBS", 
        "JOBURG", 
        "JOT", 
        "JOY", 
        "JP", 
        "JPMORGAN", 
        "JPRS", 
        "JUEGOS", 
        "JUNIPER", 
        "KAUFEN", 
        "KDDI", 
        "KE", 
        "KERRYHOTELS", 
        "KERRYLOGISTICS", 
        "KERRYPROPERTIES", 
        "KFH", 
        "KG", 
        "KH", 
        "KI", 
        "KIA", 
        "KIDS", 
        "KIM", 
        "KINDLE", 
        "KITCHEN", 
        "KIWI", 
        "KM", 
        "KN", 
        "KOELN", 
        "KOMATSU", 
        "KOSHER", 
        "KP", 
        "KPMG", 
        "KPN", 
        "KR", 
        "KRD", 
        "KRED", 
        "KUOKGROUP", 
        "KW", 
        "KY", 
        "KYOTO", 
        "KZ", 
        "LA", 
        "LACAIXA", 
        "LAMBORGHINI", 
        "LAMER", 
        "LANCASTER", 
        "LAND", 
        "LANDROVER", 
        "LANXESS", 
        "LASALLE", 
        "LAT", 
        "LATINO", 
        "LATROBE", 
        "LAW", 
        "LAWYER", 
        "LB", 
        "LC", 
        "LDS", 
        "LEASE", 
        "LECLERC", 
        "LEFRAK", 
        "LEGAL", 
        "LEGO", 
        "LEXUS", 
        "LGBT", 
        "LI", 
        "LIDL", 
        "LIFE", 
        "LIFEINSURANCE", 
        "LIFESTYLE", 
        "LIGHTING", 
        "LIKE", 
        "LILLY", 
        "LIMITED", 
        "LIMO", 
        "LINCOLN", 
        "LINK", 
        "LIPSY", 
        "LIVE", 
        "LIVING", 
        "LK", 
        "LLC", 
        "LLP", 
        "LOAN", 
        "LOANS", 
        "LOCKER", 
        "LOCUS", 
        "LOL", 
        "LONDON", 
        "LOTTE", 
        "LOTTO", 
        "LOVE", 
        "LPL", 
        "LPLFINANCIAL", 
        "LR", 
        "LS", 
        "LT", 
        "LTD", 
        "LTDA", 
        "LU", 
        "LUNDBECK", 
        "LUXE", 
        "LUXURY", 
        "LV", 
        "LY", 
        "MA", 
        "MADRID", 
        "MAIF", 
        "MAISON", 
        "MAKEUP", 
        "MAN", 
        "MANAGEMENT", 
        "MANGO", 
        "MAP", 
        "MARKET", 
        "MARKETING", 
        "MARKETS", 
        "MARRIOTT", 
        "MARSHALLS", 
        "MATTEL", 
        "MBA", 
        "MC", 
        "MCKINSEY", 
        "MD", 
        "ME", 
        "MED", 
        "MEDIA", 
        "MEET", 
        "MELBOURNE", 
        "MEME", 
        "MEMORIAL", 
        "MEN", 
        "MENU", 
        "MERCKMSD", 
        "MG", 
        "MH", 
        "MIAMI", 
        "MICROSOFT", 
        "MIL", 
        "MINI", 
        "MINT", 
        "MIT", 
        "MITSUBISHI", 
        "MK", 
        "ML", 
        "MLB", 
        "MLS", 
        "MM", 
        "MMA", 
        "MN", 
        "MO", 
        "MOBI", 
        "MOBILE", 
        "MODA", 
        "MOE", 
        "MOI", 
        "MOM", 
        "MONASH", 
        "MONEY", 
        "MONSTER", 
        "MORMON", 
        "MORTGAGE", 
        "MOSCOW", 
        "MOTO", 
        "MOTORCYCLES", 
        "MOV", 
        "MOVIE", 
        "MP", 
        "MQ", 
        "MR", 
        "MS", 
        "MSD", 
        "MT", 
        "MTN", 
        "MTR", 
        "MU", 
        "MUSEUM", 
        "MUSIC", 
        "MV", 
        "MW", 
        "MX", 
        "MY", 
        "MZ", 
        "NA", 
        "NAB", 
        "NAGOYA", 
        "NAME", 
        "NAVY", 
        "NBA", 
        "NC", 
        "NE", 
        "NEC", 
        "NET", 
        "NETBANK", 
        "NETFLIX", 
        "NETWORK", 
        "NEUSTAR", 
        "NEW", 
        "NEWS", 
        "NEXT", 
        "NEXTDIRECT", 
        "NEXUS", 
        "NF", 
        "NFL", 
        "NG", 
        "NGO", 
        "NHK", 
        "NI", 
        "NICO", 
        "NIKE", 
        "NIKON", 
        "NINJA", 
        "NISSAN", 
        "NISSAY", 
        "NL", 
        "NO", 
        "NOKIA", 
        "NORTON", 
        "NOW", 
        "NOWRUZ", 
        "NOWTV", 
        "NP", 
        "NR", 
        "NRA", 
        "NRW", 
        "NTT", 
        "NU", 
        "NYC", 
        "NZ", 
        "OBI", 
        "OBSERVER", 
        "OFFICE", 
        "OKINAWA", 
        "OLAYAN", 
        "OLAYANGROUP", 
        "OLLO", 
        "OM", 
        "OMEGA", 
        "ONE", 
        "ONG", 
        "ONL", 
        "ONLINE", 
        "OOO", 
        "OPEN", 
        "ORACLE", 
        "ORANGE", 
        "ORG", 
        "ORGANIC", 
        "ORIGINS", 
        "OSAKA", 
        "OTSUKA", 
        "OTT", 
        "OVH", 
        "PA", 
        "PAGE", 
        "PANASONIC", 
        "PARIS", 
        "PARS", 
        "PARTNERS", 
        "PARTS", 
        "PARTY", 
        "PAY", 
        "PCCW", 
        "PE", 
        "PET", 
        "PF", 
        "PFIZER", 
        "PG", 
        "PH", 
        "PHARMACY", 
        "PHD", 
        "PHILIPS", 
        "PHONE", 
        "PHOTO", 
        "PHOTOGRAPHY", 
        "PHOTOS", 
        "PHYSIO", 
        "PICS", 
        "PICTET", 
        "PICTURES", 
        "PID", 
        "PIN", 
        "PING", 
        "PINK", 
        "PIONEER", 
        "PIZZA", 
        "PK", 
        "PL", 
        "PLACE", 
        "PLAY", 
        "PLAYSTATION", 
        "PLUMBING", 
        "PLUS", 
        "PM", 
        "PN", 
        "PNC", 
        "POHL", 
        "POKER", 
        "POLITIE", 
        "PORN", 
        "POST", 
        "PR", 
        "PRAMERICA", 
        "PRAXI", 
        "PRESS", 
        "PRIME", 
        "PRO", 
        "PROD", 
        "PRODUCTIONS", 
        "PROF", 
        "PROGRESSIVE", 
        "PROMO", 
        "PROPERTIES", 
        "PROPERTY", 
        "PROTECTION", 
        "PRU", 
        "PRUDENTIAL", 
        "PS", 
        "PT", 
        "PUB", 
        "PW", 
        "PWC", 
        "PY", 
        "QA", 
        "QPON", 
        "QUEBEC", 
        "QUEST", 
        "RACING", 
        "RADIO", 
        "RE", 
        "READ", 
        "REALESTATE", 
        "REALTOR", 
        "REALTY", 
        "RECIPES", 
        "RED", 
        "REDSTONE", 
        "REDUMBRELLA", 
        "REHAB", 
        "REISE", 
        "REISEN", 
        "REIT", 
        "RELIANCE", 
        "REN", 
        "RENT", 
        "RENTALS", 
        "REPAIR", 
        "REPORT", 
        "REPUBLICAN", 
        "REST", 
        "RESTAURANT", 
        "REVIEW", 
        "REVIEWS", 
        "REXROTH", 
        "RICH", 
        "RICHARDLI", 
        "RICOH", 
        "RIL", 
        "RIO", 
        "RIP", 
        "RO", 
        "ROCKS", 
        "RODEO", 
        "ROGERS", 
        "ROOM", 
        "RS", 
        "RSVP", 
        "RU", 
        "RUGBY", 
        "RUHR", 
        "RUN", 
        "RW", 
        "RWE", 
        "RYUKYU", 
        "SA", 
        "SAARLAND", 
        "SAFE", 
        "SAFETY", 
        "SAKURA", 
        "SALE", 
        "SALON", 
        "SAMSCLUB", 
        "SAMSUNG", 
        "SANDVIK", 
        "SANDVIKCOROMANT", 
        "SANOFI", 
        "SAP", 
        "SARL", 
        "SAS", 
        "SAVE", 
        "SAXO", 
        "SB", 
        "SBI", 
        "SBS", 
        "SC", 
        "SCB", 
        "SCHAEFFLER", 
        "SCHMIDT", 
        "SCHOLARSHIPS", 
        "SCHOOL", 
        "SCHULE", 
        "SCHWARZ", 
        "SCIENCE", 
        "SCOT", 
        "SD", 
        "SE", 
        "SEARCH", 
        "SEAT", 
        "SECURE", 
        "SECURITY", 
        "SEEK", 
        "SELECT", 
        "SENER", 
        "SERVICES", 
        "SEVEN", 
        "SEW", 
        "SEX", 
        "SEXY", 
        "SFR", 
        "SG", 
        "SH", 
        "SHANGRILA", 
        "SHARP", 
        "SHELL", 
        "SHIA", 
        "SHIKSHA", 
        "SHOES", 
        "SHOP", 
        "SHOPPING", 
        "SHOUJI", 
        "SHOW", 
        "SI", 
        "SILK", 
        "SINA", 
        "SINGLES", 
        "SITE", 
        "SJ", 
        "SK", 
        "SKI", 
        "SKIN", 
        "SKY", 
        "SKYPE", 
        "SL", 
        "SLING", 
        "SM", 
        "SMART", 
        "SMILE", 
        "SN", 
        "SNCF", 
        "SO", 
        "SOCCER", 
        "SOCIAL", 
        "SOFTBANK", 
        "SOFTWARE", 
        "SOHU", 
        "SOLAR", 
        "SOLUTIONS", 
        "SONG", 
        "SONY", 
        "SOY", 
        "SPA", 
        "SPACE", 
        "SPORT", 
        "SPOT", 
        "SR", 
        "SRL", 
        "SS", 
        "ST", 
        "STADA", 
        "STAPLES", 
        "STAR", 
        "STATEBANK", 
        "STATEFARM", 
        "STC", 
        "STCGROUP", 
        "STOCKHOLM", 
        "STORAGE", 
        "STORE", 
        "STREAM", 
        "STUDIO", 
        "STUDY", 
        "STYLE", 
        "SU", 
        "SUCKS", 
        "SUPPLIES", 
        "SUPPLY", 
        "SUPPORT", 
        "SURF", 
        "SURGERY", 
        "SUZUKI", 
        "SV", 
        "SWATCH", 
        "SWISS", 
        "SX", 
        "SY", 
        "SYDNEY", 
        "SYSTEMS", 
        "SZ", 
        "TAB", 
        "TAIPEI", 
        "TALK", 
        "TAOBAO", 
        "TARGET", 
        "TATAMOTORS", 
        "TATAR", 
        "TATTOO", 
        "TAX", 
        "TAXI", 
        "TC", 
        "TCI", 
        "TD", 
        "TDK", 
        "TEAM", 
        "TECH", 
        "TECHNOLOGY", 
        "TEL", 
        "TEMASEK", 
        "TENNIS", 
        "TEVA", 
        "TF", 
        "TG", 
        "TH", 
        "THD", 
        "THEATER", 
        "THEATRE", 
        "TIAA", 
        "TICKETS", 
        "TIENDA", 
        "TIPS", 
        "TIRES", 
        "TIROL", 
        "TJ", 
        "TJMAXX", 
        "TJX", 
        "TK", 
        "TKMAXX", 
        "TL", 
        "TM", 
        "TMALL", 
        "TN", 
        "TO", 
        "TODAY", 
        "TOKYO", 
        "TOOLS", 
        "TOP", 
        "TORAY", 
        "TOSHIBA", 
        "TOTAL", 
        "TOURS", 
        "TOWN", 
        "TOYOTA", 
        "TOYS", 
        "TR", 
        "TRADE", 
        "TRADING", 
        "TRAINING", 
        "TRAVEL", 
        "TRAVELERS", 
        "TRAVELERSINSURANCE", 
        "TRUST", 
        "TRV", 
        "TT", 
        "TUBE", 
        "TUI", 
        "TUNES", 
        "TUSHU", 
        "TV", 
        "TVS", 
        "TW", 
        "TZ", 
        "UA", 
        "UBANK", 
        "UBS", 
        "UG", 
        "UK", 
        "UNICOM", 
        "UNIVERSITY", 
        "UNO", 
        "UOL", 
        "UPS", 
        "US", 
        "UY", 
        "UZ", 
        "VA", 
        "VACATIONS", 
        "VANA", 
        "VANGUARD", 
        "VC", 
        "VE", 
        "VEGAS", 
        "VENTURES", 
        "VERISIGN", 
        "VERSICHERUNG", 
        "VET", 
        "VG", 
        "VI", 
        "VIAJES", 
        "VIDEO", 
        "VIG", 
        "VIKING", 
        "VILLAS", 
        "VIN", 
        "VIP", 
        "VIRGIN", 
        "VISA", 
        "VISION", 
        "VIVA", 
        "VIVO", 
        "VLAANDEREN", 
        "VN", 
        "VODKA", 
        "VOLVO", 
        "VOTE", 
        "VOTING", 
        "VOTO", 
        "VOYAGE", 
        "VU", 
        "WALES", 
        "WALMART", 
        "WALTER", 
        "WANG", 
        "WANGGOU", 
        "WATCH", 
        "WATCHES", 
        "WEATHER", 
        "WEATHERCHANNEL", 
        "WEBCAM", 
        "WEBER", 
        "WEBSITE", 
        "WED", 
        "WEDDING", 
        "WEIBO", 
        "WEIR", 
        "WF", 
        "WHOSWHO", 
        "WIEN", 
        "WIKI", 
        "WILLIAMHILL", 
        "WIN", 
        "WINDOWS", 
        "WINE", 
        "WINNERS", 
        "WME", 
        "WOLTERSKLUWER", 
        "WOODSIDE", 
        "WORK", 
        "WORKS", 
        "WORLD", 
        "WOW", 
        "WS", 
        "WTC", 
        "WTF", 
        "XBOX", 
        "XEROX", 
        "XIHUAN", 
        "XIN", 
        "XN--11B4C3D", 
        "XN--1CK2E1B", 
        "XN--1QQW23A", 
        "XN--2SCRJ9C", 
        "XN--30RR7Y", 
        "XN--3BST00M", 
        "XN--3DS443G", 
        "XN--3E0B707E", 
        "XN--3HCRJ9C", 
        "XN--3PXU8K", 
        "XN--42C2D9A", 
        "XN--45BR5CYL", 
        "XN--45BRJ9C", 
        "XN--45Q11C", 
        "XN--4DBRK0CE", 
        "XN--4GBRIM", 
        "XN--54B7FTA0CC", 
        "XN--55QW42G", 
        "XN--55QX5D", 
        "XN--5SU34J936BGSG", 
        "XN--5TZM5G", 
        "XN--6FRZ82G", 
        "XN--6QQ986B3XL", 
        "XN--80ADXHKS", 
        "XN--80AO21A", 
        "XN--80AQECDR1A", 
        "XN--80ASEHDB", 
        "XN--80ASWG", 
        "XN--8Y0A063A", 
        "XN--90A3AC", 
        "XN--90AE", 
        "XN--90AIS", 
        "XN--9DBQ2A", 
        "XN--9ET52U", 
        "XN--9KRT00A", 
        "XN--B4W605FERD", 
        "XN--BCK1B9A5DRE4C", 
        "XN--C1AVG", 
        "XN--C2BR7G", 
        "XN--CCK2B3B", 
        "XN--CCKWCXETD", 
        "XN--CG4BKI", 
        "XN--CLCHC0EA0B2G2A9GCD", 
        "XN--CZR694B", 
        "XN--CZRS0T", 
        "XN--CZRU2D", 
        "XN--D1ACJ3B", 
        "XN--D1ALF", 
        "XN--E1A4C", 
        "XN--ECKVDTC9D", 
        "XN--EFVY88H", 
        "XN--FCT429K", 
        "XN--FHBEI", 
        "XN--FIQ228C5HS", 
        "XN--FIQ64B", 
        "XN--FIQS8S", 
        "XN--FIQZ9S", 
        "XN--FJQ720A", 
        "XN--FLW351E", 
        "XN--FPCRJ9C3D", 
        "XN--FZC2C9E2C", 
        "XN--FZYS8D69UVGM", 
        "XN--G2XX48C", 
        "XN--GCKR3F0F", 
        "XN--GECRJ9C", 
        "XN--GK3AT1E", 
        "XN--H2BREG3EVE", 
        "XN--H2BRJ9C", 
        "XN--H2BRJ9C8C", 
        "XN--HXT814E", 
        "XN--I1B6B1A6A2E", 
        "XN--IMR513N", 
        "XN--IO0A7I", 
        "XN--J1AEF", 
        "XN--J1AMH", 
        "XN--J6W193G", 
        "XN--JLQ480N2RG", 
        "XN--JVR189M", 
        "XN--KCRX77D1X4A", 
        "XN--KPRW13D", 
        "XN--KPRY57D", 
        "XN--KPUT3I", 
        "XN--L1ACC", 
        "XN--LGBBAT1AD8J", 
        "XN--MGB9AWBF", 
        "XN--MGBA3A3EJT", 
        "XN--MGBA3A4F16A", 
        "XN--MGBA7C0BBN0A", 
        "XN--MGBAAM7A8H", 
        "XN--MGBAB2BD", 
        "XN--MGBAH1A3HJKRD", 
        "XN--MGBAI9AZGQP6J", 
        "XN--MGBAYH7GPA", 
        "XN--MGBBH1A", 
        "XN--MGBBH1A71E", 
        "XN--MGBC0A9AZCG", 
        "XN--MGBCA7DZDO", 
        "XN--MGBCPQ6GPA1A", 
        "XN--MGBERP4A5D4AR", 
        "XN--MGBGU82A", 
        "XN--MGBI4ECEXP", 
        "XN--MGBPL2FH", 
        "XN--MGBT3DHD", 
        "XN--MGBTX2B", 
        "XN--MGBX4CD0AB", 
        "XN--MIX891F", 
        "XN--MK1BU44C", 
        "XN--MXTQ1M", 
        "XN--NGBC5AZD", 
        "XN--NGBE9E0A", 
        "XN--NGBRX", 
        "XN--NODE", 
        "XN--NQV7F", 
        "XN--NQV7FS00EMA", 
        "XN--NYQY26A", 
        "XN--O3CW4H", 
        "XN--OGBPF8FL", 
        "XN--OTU796D", 
        "XN--P1ACF", 
        "XN--P1AI", 
        "XN--PGBS0DH", 
        "XN--PSSY2U", 
        "XN--Q7CE6A", 
        "XN--Q9JYB4C", 
        "XN--QCKA1PMC", 
        "XN--QXA6A", 
        "XN--QXAM", 
        "XN--RHQV96G", 
        "XN--ROVU88B", 
        "XN--RVC1E0AM3E", 
        "XN--S9BRJ9C", 
        "XN--SES554G", 
        "XN--T60B56A", 
        "XN--TCKWE", 
        "XN--TIQ49XQYJ", 
        "XN--UNUP4Y", 
        "XN--VERMGENSBERATER-CTB", 
        "XN--VERMGENSBERATUNG-PWB", 
        "XN--VHQUV", 
        "XN--VUQ861B", 
        "XN--W4R85EL8FHU5DNRA", 
        "XN--W4RS40L", 
        "XN--WGBH1C", 
        "XN--WGBL6A", 
        "XN--XHQ521B", 
        "XN--XKC2AL3HYE2A", 
        "XN--XKC2DL3A5EE0H", 
        "XN--Y9A3AQ", 
        "XN--YFRO4I67O", 
        "XN--YGBI2AMMX", 
        "XN--ZFR164B", 
        "XXX", 
        "XYZ", 
        "YACHTS", 
        "YAHOO", 
        "YAMAXUN", 
        "YANDEX", 
        "YE", 
        "YODOBASHI", 
        "YOGA", 
        "YOKOHAMA", 
        "YOU", 
        "YOUTUBE", 
        "YT", 
        "YUN", 
        "ZA", 
        "ZAPPOS", 
        "ZARA", 
        "ZERO", 
        "ZIP", 
        "ZM", 
        "ZONE", 
        "ZUERICH", 
        "ZW", 
        "कॉम", 
        "セール", 
        "佛山", 
        "ಭಾರತ", 
        "慈善", 
        "集团", 
        "在线", 
        "한국", 
        "ଭାରତ", 
        "点看", 
        "คอม", 
        "ভাৰত", 
        "ভারত", 
        "八卦", 
        "ישראל", 
        "موقع", 
        "বাংলা", 
        "公益", 
        "公司", 
        "香格里拉", 
        "网站", 
        "移动", 
        "我爱你", 
        "москва", 
        "қаз", 
        "католик", 
        "онлайн", 
        "сайт", 
        "联通", 
        "срб", 
        "бг", 
        "бел", 
        "קום", 
        "时尚", 
        "微博", 
        "淡马锡", 
        "ファッション", 
        "орг", 
        "नेट", 
        "ストア", 
        "アマゾン", 
        "삼성", 
        "சிங்கப்பூர்", 
        "商标", 
        "商店", 
        "商城", 
        "дети", 
        "мкд", 
        "ею", 
        "ポイント", 
        "新闻", 
        "家電", 
        "كوم", 
        "中文网", 
        "中信", 
        "中国", 
        "中國", 
        "娱乐", 
        "谷歌", 
        "భారత్", 
        "ලංකා", 
        "電訊盈科", 
        "购物", 
        "クラウド", 
        "ભારત", 
        "通販", 
        "भारतम्", 
        "भारत", 
        "भारोत", 
        "网店", 
        "संगठन", 
        "餐厅", 
        "网络", 
        "ком", 
        "укр", 
        "香港", 
        "亚马逊", 
        "食品", 
        "飞利浦", 
        "台湾", 
        "台灣", 
        "手机", 
        "мон", 
        "الجزائر", 
        "عمان", 
        "ارامكو", 
        "ایران", 
        "العليان", 
        "امارات", 
        "بازار", 
        "موريتانيا", 
        "پاکستان", 
        "الاردن", 
        "بارت", 
        "بھارت", 
        "المغرب", 
        "ابوظبي", 
        "البحرين", 
        "السعودية", 
        "ڀارت", 
        "كاثوليك", 
        "سودان", 
        "همراه", 
        "عراق", 
        "مليسيا", 
        "澳門", 
        "닷컴", 
        "政府", 
        "شبكة", 
        "بيتك", 
        "عرب", 
        "გე", 
        "机构", 
        "组织机构", 
        "健康", 
        "ไทย", 
        "سورية", 
        "招聘", 
        "рус", 
        "рф", 
        "تونس", 
        "大拿", 
        "ລາວ", 
        "みんな", 
        "グーグル", 
        "ευ", 
        "ελ", 
        "世界", 
        "書籍", 
        "ഭാരതം", 
        "ਭਾਰਤ", 
        "网址", 
        "닷넷", 
        "コム", 
        "天主教", 
        "游戏", 
        "vermögensberater", 
        "vermögensberatung", 
        "企业", 
        "信息", 
        "嘉里大酒店", 
        "嘉里", 
        "مصر", 
        "قطر", 
        "广东", 
        "இலங்கை", 
        "இந்தியா", 
        "հայ", 
        "新加坡", 
        "فلسطين", 
        "政务"
      ]
    }
  },
  props: {
    searchMode: {
      type: String,
    }
  },
  computed: {
    ...mapState({
      vatDisplay: (state) => state.vatDisplay,
    })
  },
  watch: {
    $route(to, from) {
      if (from.name != to.name) {
        // reset component when URL for this component changes
        this.isDomainSearchActive = false;
        this.domainName = null;
      }
    },
    vatDisplay: function () {
      this.initPricesImmediately();
    }
  },
  async created() {
    // if from another location a domain name was given, then start immediately with the search
    if(this.$route.query.domain) {
      this.domainName = this.$route.query.domain;
      // remove parameters from URL
      this.$router.replace({'query': null});
      this.isDomainSearchActive = true;
      // load prices and wait for the result
      this.initPricesImmediately();
    } else {
      // load prices but don't wait for the result because it is not needed immediately
      this.initPrices();
    }

    this.initWebSocket();
  },
  methods: {
    initWebSocket() {
      var self = this;
      
      //console.log("Starting connection to websocket server")
      this.connection = new WebSocket("wss://api.manotori.com/ws")

      this.connection.onmessage = (event) => {
        if (event.data == "ping") {
          this.connection.send("pong");
          return;
        }

        var res = JSON.parse(event.data)
        //console.log(res);
        if (event.error) {
          //console.log(res);
        } else {
          // In case of fast checkbox checking/unchecking it is important to
          // check if the element (resp. TLD) is still in the result object.
          if (Object.prototype.hasOwnProperty.call(this.searchResult, res.tld)) {
            // free is a boolean and to understand as isFree
            if (res.free) {
              if (res.success) {
                this.searchResult[res.tld] = res.free;
              } else {
                this.searchResult[res.tld] = "failed"
              }
            } else {
              // Delete result rows when the customer only want to see available domains.
              // Only exception to this rule is when the customer searched for a specific domain.
              if (this.useOnlyAvailableDomains && !res.free && res.tld != this.tld) {
                delete this.searchResult[res.tld];
              } else {
                if (res.success) {
                  this.searchResult[res.tld] = res.free;
                } else {
                  this.searchResult[res.tld] = "failed"
                }
              }
            }
          }
        }
      }

      this.connection.onopen = () => {
        //console.log("Successfully connected to the websocket server...");
        // if from another location a domain name was given, then start immediately with the search
        if(this.isDomainSearchActive) {
          // execute domain search
          this.searchDomain();
        } else {
          // load prices but don't wait for the result because it is not needed immediately
          this.initPrices();
        }
      }

      this.connection.onclose = () => {
        //console.log("Close websocket connection")
        //this.connection = null;
        setTimeout(function() {
          self.initWebSocket()
        }, 1000);
      }

      this.connection.onerror = function(event) {
        console.error("Websocket error: " + event)
      }
    },
    initPrices() {
      try {
        var self = this;

        if (this.checkAuthUser()) {
          this.req("GET", `/v1/accounting/priceList`).
          then(function(result) {
            self.processPriceResult(self, result.status, result.data.data.price_list);
          });
        } else {
          if (self.$store.state.vatDisplay.country_code == 'ZZ') {
            // fetch net prices
            this.axios.get(`${this.apiUrl}/v1/accounting/priceList/public`).
            then(function(result) {
              self.processPriceResult(self, result.status, result.data.data.price_list);
            });
          } else {
            // fetch gross prices for respective country
            this.axios.get(
              `${this.apiUrl}/v1/accounting/priceList/public`,
              {params:{country_code: self.$store.state.vatDisplay.country_code}}
            ).
            then(function(result) {
              self.processPriceResult(self, result.status, result.data.data.price_list);
            });
          }
        }
      } catch (error) {
          console.error(error);
      }
    },
    async initPricesImmediately() {
      try {
        let result;
        if (this.checkAuthUser()) {
          result = await this.req("GET", `/v1/accounting/priceList`);
        } else {
          if (this.$store.state.vatDisplay.country_code == 'ZZ') {
            // fetch net prices
            result = await this.axios.get(
              `${this.apiUrl}/v1/accounting/priceList/public`
            );
          } else {
            // fetch gross prices for respective country
            result = await this.axios.get(
              `${this.apiUrl}/v1/accounting/priceList/public`,
              {params:{country_code: this.$store.state.vatDisplay.country_code}}
            );
          }
        }
        this.processPriceResult(this, result.status, result.data.data.price_list);
      } catch (error) {
        console.error(error);
      }
    },
    processPriceResult(thisPointer, httpStatus, priceList) {
      if(httpStatus == 200) {
        priceList.forEach((element) => {
          thisPointer.tldRegistrationPrices[element.tld] = this.convertToCurrencyFormat(element.registration_gross_price);
          thisPointer.tldRegistrationInclPeriod[element.tld] = element.registration_included_period;
          thisPointer.tldTransferPrices[element.tld] = this.convertToCurrencyFormat(element.transfer_gross_price);
          thisPointer.tldTransferInclPeriod[element.tld] = element.transfer_included_period;
        });
      }
    },
    async initTldSearch() {
      // check if the TLD information have already been loaded
      if (!this.isTldDataLoaded) {
        try {
          const result = await this.axios.get(`${this.apiUrl}/v1/tld/overview/all`, {});
          if(result.status == 200){
            this.typeGtlds = result.data.data.gTLD;
            this.typeNtlds = result.data.data.nTLD;
            this.typeCctlds = result.data.data.ccTLD;
            this.regionEurope = result.data.data.region_europe;
            this.regionAsia = result.data.data.region_asia;
            this.regionAmerica = result.data.data.region_america;
            this.regionAfrica = result.data.data.region_afric;
            this.regionAustraliaOceania = result.data.data.region_australia_oceania;
            this.mostRelevant = result.data.data.most_relevant;
            this.specialOffer = result.data.data.special_offer;

            this.isTldDataLoaded = true;
          }
        } catch (error) {
          console.error('GET /v1/tld/overview/all failed');
        }
      }
    },
    async searchDomain(searchOption) {
      if (typeof this.domainName !== 'undefined' &&
          this.domainName.length != 0 &&
          this.domainName.trim().length != 0
      ) {
        await this.initTldSearch();

        this.searchResult = {};
        this.isDomainSearchActive = true;
        this.isTldUnsupported = false;
        this.domainName = this.domainName.toLowerCase();
        this.domainName = this.domainName.trim();
        // replace remaining spaces with -
        this.domainName = this.domainName.replace(/\s/g, '-');
        // remove special characters
        // eslint-disable-next-line
        this.domainName = this.domainName.replace(/[!@#&,_:;\^"§\$%\/\(\)=\?\\`´'\+\|<>\*\[\]]/g, '');

        var trimmedDomain = this.domainName
        var firstDotLocation = trimmedDomain.search('\\.');

        if (firstDotLocation == -1) {
          this.tld = null;
          this.domain = trimmedDomain;
        } else {
          this.tld = trimmedDomain.substring(firstDotLocation + 1);
          this.tld = this.tld.toUpperCase();
          this.domain = trimmedDomain.substring(0, firstDotLocation);
        }

        switch (searchOption) {
          case 'europe':
            this.revertSpecialLogic();
            this.useRegionEurope = this.changeSearchOption(this.useRegionEurope);
            break;
          case 'asia':
            this.revertSpecialLogic();
            this.useRegionAsia = this.changeSearchOption(this.useRegionAsia);
            break;
          case 'america':
            this.revertSpecialLogic();
            this.useRegionAmerica = this.changeSearchOption(this.useRegionAmerica);
            break;
          case 'africa':
            this.revertSpecialLogic();
            this.useRegionAfrica = this.changeSearchOption(this.useRegionAfrica);
            break;
          case 'australiaAndOceania':
            this.revertSpecialLogic();
            this.useRegionAustraliaOceania = this.changeSearchOption(this.useRegionAustraliaOceania);
            break;
          case 'gTlds':
            this.revertSpecialLogic();
            this.useTypeGtlds = this.changeSearchOption(this.useTypeGtlds);
            break;
          case 'ccTlds':
            this.revertSpecialLogic();
            this.useTypeCctlds = this.changeSearchOption(this.useTypeCctlds);
            break;
          case 'newTlds':
            this.revertSpecialLogic();
            this.useTypeNtlds = this.changeSearchOption(this.useTypeNtlds);
            break;
          case 'mostRelevantDomains':
            this.revertSpecialLogic();
            this.useMostRelevant = this.changeSearchOption(this.useMostRelevant);
            break;
          case 'promotions':
            this.revertSpecialLogic();
            this.useSpecialOffer = this.changeSearchOption(this.useSpecialOffer);
            break;
          case 'onlyAvailableDomains':
            // exception from special logic -> no revertSpecialLogic() necessary
            this.useOnlyAvailableDomains = this.changeSearchOption(this.useOnlyAvailableDomains);
            break;
        }

        if (!this.useRegionEurope && !this.useRegionAsia && !this.useRegionAmerica && !this.useRegionAfrica && !this.useRegionAustraliaOceania &&
            !this.useTypeGtlds && !this.useTypeCctlds && !this.useTypeNtlds && !this.useMostRelevant && !this.useSpecialOffer) {
          // special logic is active
          this.isOverrideActive = true;

          this.useRegionEurope = true;
          this.useRegionAsia = true;
          this.useRegionAmerica = true;
          this.useRegionAfrica = true;
          this.useRegionAustraliaOceania = true;
          this.useTypeGtlds = true;
          this.useTypeCctlds = true;
          this.useTypeNtlds = true;
          this.useMostRelevant = true;
          this.useSpecialOffer = true;
        }

        // collect all supported TLDs
        let tldAll = [];
        tldAll = tldAll.concat(this.regionEurope);
        tldAll = tldAll.concat(this.regionAsia);
        tldAll = tldAll.concat(this.regionAmerica);
        tldAll = tldAll.concat(this.regionAfrica);
        tldAll = tldAll.concat(this.regionAustraliaOceania);
        tldAll = tldAll.concat(this.typeGtlds);
        tldAll = tldAll.concat(this.typeCctlds);
        tldAll = tldAll.concat(this.typeNtlds);
        tldAll = tldAll.concat(this.mostRelevant);
        tldAll = tldAll.concat(this.specialOffer);
        
        let tldArray = [];
        if (this.useRegionEurope) {
          tldArray = tldArray.concat(this.regionEurope);
        }
        if (this.useRegionAsia) {
          tldArray = tldArray.concat(this.regionAsia);
        }
        if (this.useRegionAmerica) {
          tldArray = tldArray.concat(this.regionAmerica);
        }
        if (this.useRegionAfrica) {
          tldArray = tldArray.concat(this.regionAfrica);
        }
        if (this.useRegionAustraliaOceania) {
          tldArray = tldArray.concat(this.regionAustraliaOceania);
        }

        if (this.useTypeGtlds) {
          tldArray = tldArray.concat(this.typeGtlds);
        }
        if (this.useTypeCctlds) {
          tldArray = tldArray.concat(this.typeCctlds);
        }
        if (this.useTypeNtlds) {
          tldArray = tldArray.concat(this.typeNtlds);
        }

        if (this.useMostRelevant) {
          tldArray = tldArray.concat(this.mostRelevant);
        }
        if (this.useSpecialOffer) {
          tldArray = tldArray.concat(this.specialOffer);
        }

        // add the desired TLD if not already included in the search array and if the TLD is acutally supported
        if (this.tld != null && !tldArray.includes(this.tld) && tldAll.includes(this.tld)) {
          tldArray = tldArray.concat([this.tld]);
        } else if (this.tld != null && this.tldList.includes(this.tld) && !tldAll.includes(this.tld)) {
          this.isTldUnsupported = true;
        } else if (!tldAll.includes(this.tld)) {
          if (this.tld != null) {
            // remove unsupported TLD from domain
            this.domainName = this.domainName.replace('.' + this.tld.toLowerCase(), '');
            // clear TLD information since it is invalid
            this.tld = null;
          }
        }

        let checkRequest = {
            "domain": this.domain,
            "tld": tldArray,
        }
        checkRequest.tld.forEach(element => this.searchResult[element] = 'pending');

        //console.log("Domain Check Request: " + JSON.stringify(checkRequest));
        this.connection.send(JSON.stringify(checkRequest));
      }
    },
    changeSearchOption(searchOption) {
      if (searchOption) {
        return false;
      } else {
        return true;
      }
    },
    async addToShoppingCart(domain, status, addToShoppingList = false) {
      try {
        let action = "";
        // boolean status if the domain is available for registration
        if (status) {
          action = "REGISTRATION"
        } else {
          action = "TRANSFER"
        }

        const body = {
          domain: domain,
          action: action
        };

        if (this.checkAuthUser()) {
          const httpStatus = await this.addToShoppingCartRequest(body, addToShoppingList);
          if (httpStatus == 436 || httpStatus == 437 || httpStatus == 438 || httpStatus == 439 || httpStatus == 412) {
            this.errorType = httpStatus;
            this.isErrorMsgModalOpen = true;
          } else if (httpStatus != 200) {
            this.isErrorMsgModalOpen = true;
          }
        } else {
          // you can only save strings in the local storage, thats the reason for the JSON functions.
          const currentLocalStorage =
            JSON.parse(localStorage.getItem('localShoppingList') || '[]').concat(JSON.parse(localStorage.getItem('localShoppingCart') || '[]'))

          if (currentLocalStorage.filter(item=>item.domain == domain && item.action == action).length == 0) {
            localStorage.setItem((addToShoppingList?'localShoppingList':'localShoppingCart'), JSON.stringify((currentLocalStorage).concat([body])))
            
            if (addToShoppingList) {
              this.$store.commit('shoppingListCountChange', 1)
            } else {
              this.$store.commit('shoppingCartCountChange', 1)
            }
          } else {
            return false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    revertSpecialLogic() {
      if (this.isOverrideActive) {
        // deactivate special logic
        this.isOverrideActive = false;

        this.useRegionEurope = false;
        this.useRegionAsia = false;
        this.useRegionAmerica = false;
        this.useRegionAfrica = false;
        this.useRegionAustraliaOceania = false;
        this.useTypeGtlds = false;
        this.useTypeCctlds = false;
        this.useTypeNtlds = false;
        this.useMostRelevant = false;
        this.useSpecialOffer = false;
      }
    }
  }
}
</script>
