/*eslint-disable*/
var jwt = require('jwt-simple');

function checkPrivilege(reqPrivilege) {
  try {
    const localJwtToken = localStorage.getItem('authToken');
    const localKey = localStorage.getItem('authKey');

    if (!localJwtToken || localJwtToken == null || !localKey || localKey == null) {
      return false
    }

    const decoded = jwt.decode(localJwtToken, localKey)
    if (Date.now() > decoded.exp && decoded.access.includes(reqPrivilege)) {
      return true
    } else {
      return false
    }

  } catch (error) {
    error
    return false
  }
}

module.exports = { checkPrivilege };
