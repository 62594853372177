/* Import Plugins */
import { createApp, h } from 'vue'
import i18n from './i18n'
//import VueEasyLightbox from 'vue-easy-lightbox'   // package vue-easy-lightbox
import axios from 'axios'
import { jwtDecode } from "jwt-decode";
var jwt = require('jwt-simple');
import store from '@/store'

import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { setLocale } from '@vee-validate/i18n';
import { defineRule } from 'vee-validate';
import { required, confirmed, min, max, min_value, max_value, email, regex, is, alpha_num, numeric} from '@vee-validate/rules';

import VueMatomo from 'vue-matomo'

import Title from '@/components/information_components/Title'
import BodyScrollLock from '@/plugins/BodyScrollLock'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


/* VeeValidate */
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('email', email);
defineRule('regex', regex);
defineRule('is', is);
defineRule('alpha_num', alpha_num);
defineRule('numeric', numeric);

import namesDE from './lang/translations/de/names'
import namesEN from './lang/translations/en/names'
import validationDE from './lang/translations/de/validation'
import validationEN from './lang/translations/en/validation'

const i18nVeeValidate = {
  de: {
    names: namesDE,
    messages: validationDE.messages
  },
  en: {
    names: namesEN,
    messages: validationEN.messages
  },
};
configure({
  generateMessage: localize(i18nVeeValidate),
});

/* Import internal Components and Resources */
import App from './App.vue'
import router from './router'

/*  Styles */

// Libaries
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// Custom
import './assets/css/mulish.scss'
import './assets/css/base.scss'

i18n.setup()

/* Create Vue App */
const app = createApp(App)

// Variables
app.config.globalProperties.apiUrl="https://api.manotori.com"
app.config.globalProperties.helpSystemUrlEn="https://help.manotori.com"
app.config.globalProperties.helpSystemUrlDe="https://hilfe.manotori.com"
app.config.globalProperties.stripePubKey="pk_live_51IR13lHRtjp2LcELYUcOAHr5cjzn0NwX3NFCszpNDcbstf7PLIUeGpmORJha3SxfBGZNecJ8NhMOkEcHDq2eNpwI00rvdhdtn7"

/* Connection Plugins -> Vue App */
//vue-i18n
app.mixin({
  created() {
    if(this.$route.params.locale == 'de' && this.$root.$i18n.locale != 'de'){
      setLocale('de')
      this.$root.$i18n.locale = 'de';
    }else if(this.$route.params.locale == 'en' && this.$root.$i18n.locale != 'en'){
      setLocale('en')
      this.$root.$i18n.locale = 'en';
    }
  }
})

// vue.use
app.use(i18n.vueI18n)
app.use(router)
//app.use(VueEasyLightbox)
app.use(store)
app.use(BodyScrollLock)

app.use(VueMatomo, {
  host: 'https://piwik.kompex-online.com',
  siteId: 5,
  // enables automatically registering pageviews on the router
  router: router,
  // enables link tracking on regular links. Note that this won't work for routing links
  enableLinkTracking: true,
  // require consent before sending tracking information to matomo
  requireConsent: false,
  // whether to track the initial page view
  trackInitialView: true,
  // run Matomo without cookies
  disableCookies: true,
  // enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  enableHeartBeatTimer: false,
  // set the heartbeat timer interval
  heartBeatTimerInterval: 15,
  // whether or not to log debug information
  debug: false,
  // tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  domains: undefined,
  // set this to include crossorigin attribute on the matomo script import; default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,
});

vSelect.props.components.default = () => ({
  Deselect: {
    render: () => h('span', { class: 'ddm-arrow-icon' }),
  },
  OpenIndicator: {
    render: () => h('span', { class: 'ddm-arrow-icon' }),
  },
});

app.component('vue-title', Title);
app.component('v-select', vSelect);

/* Global */
// Functions
app.config.globalProperties.axios=axios
app.config.globalProperties.jwt_decode=jwtDecode
app.config.globalProperties.jwt=jwt

/* Final Vue App Status */
app.mount('#app')