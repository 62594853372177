/*eslint-disable*/
import { checkAuth } from '../router/auth.js'
import axios from 'axios'

export default {
  _methods: {
    async req(method, url, data = {}, responseType = 'json', params = {}) {
      if (!checkAuth()) {
        return false
      }

      try {
        let authToken = localStorage.getItem("authToken")
        const result = await axios({
          method: method,
          url: `${this.apiUrl}${url}`,
          data: data,
          headers: { Authorization: `Bearer ${authToken}` },
          params: params,
          responseType: responseType,
          validateStatus: false
        })
        if (result.headers['x-update-jwt']) {
          localStorage.setItem("authToken", result.headers['x-update-jwt']);
        }
        return result

      } catch (error) {
        console.error(error);
        return false
      }
    },
    checkAuthUser() {
      return checkAuth()
    },
    async customerData() {
      const customer = await this.req('GET', '/v1/customer/me')
      return customer
    },
    async logoutSession(url = false) {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` }
        }
        const result = await this.axios.get(`${this.apiUrl}/v1/logout`, config)
        if (result.status == 200) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("authKey");
          this.$router.push({ path: `/${this.$root.$i18n.locale}/login`, query: { status: 'success', url } });
          this.$store.commit('shoppingCartCountReset');
          this.$store.commit('shoppingListCountReset');
          this.$store.commit('prepaymentOrderCountReset');
        }
      } catch (error) { console.error(error) }
    },
    forceLogoutSession(url = false) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("authKey");
      this.$router.push({ path: `/${this.$root.$i18n.locale}/login`, query: { status: 'success', url } });
      this.$store.commit('shoppingCartCountReset');
      this.$store.commit('shoppingListCountReset');
      this.$store.commit('prepaymentOrderCountReset');
    },
    seperatePhoneNumber(number, code = "number") {
      if (number == null || number == '') {
        if (code == "country") {
          return "+49"
        } else {
          return ""
        }
      } else {
        if (number.indexOf(" ") == -1) {
          return "+49"
        }
        const seperator = number.indexOf(" ")
        if (code == "country") {
          return number.substring(0, seperator)
        } else {
          return number.substring(seperator + 1)
        }
      }
    },
    isStandardMode() {
      return localStorage.getItem("operationMode") === 'STANDARD' ? true : false;
    },
    async openPDF(path) {
      const invoice = await this.req('GET', path, {}, 'blob');
      // create blob for PDF file
      var pdfBlob = new Blob([invoice.data], { type: "application/pdf" })
      const dataURI = window.URL.createObjectURL(pdfBlob);
      // open PDF file in new tab
      window.open(dataURI);
    },
    async downloadPDF(path, pdfName) {
      const invoice = await this.req('GET', path, {}, 'blob');
      // do nothing when no information is available
      if (invoice.status == 404) {
        return;
      }
      // create blob for PDF file
      var pdfBlob = new Blob([invoice.data], { type: "application/pdf" })
      const dataURI = window.URL.createObjectURL(pdfBlob);
      // download PDF file
      var a = document.createElement("a");
      a.href = dataURI;
      a.setAttribute("download", pdfName);
      a.click();
    },
    async openInvoice(invoiceId) {
      this.openPDF(`/v1/accounting/invoice/${invoiceId}/pdf`)
    },
    async downloadInvoice(invoiceId, invoiceNumber) {
      this.downloadPDF(`/v1/accounting/invoice/${invoiceId}/pdf`, invoiceNumber)
    },
    convertToDateTimeFormat(rawDateTime, showOnlyDate = false, subtractOneSecond = false) {
      var result = new Date(rawDateTime.replace(' ', 'T') + 'Z');

      if (subtractOneSecond) {
        result.setSeconds(result.getSeconds() - 1);
      }
      
      result = new Intl.DateTimeFormat(
        this.$root.$i18n.locale, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false
        }
      ).format(result);

      // show only the date without time
      if (showOnlyDate && result != null) {
        result = result.substring(0, 10);
      }
      return result;
    },
    convertToCurrencyFormat(amount, currency = 'EUR') {
      const formatter = new Intl.NumberFormat(this.$root.$i18n.locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
      })
      return formatter.format(amount);
    },
    async addToShoppingCartRequest(body, addToShoppingList = false) {
      try {
        var url = '/v1/shoppingCart/item/domain';
        if (addToShoppingList) {
          url += '?shoppingList=true';
        }
        const response = await this.req('POST', url, body);
        if (response.status == 200) {
          if (addToShoppingList) {
            this.$store.commit('shoppingListCountChange', 1)
          } else {
            this.$store.commit('shoppingCartCountChange', 1)
          }
        }
        return response.status;
      } catch (error) {
        console.error(error);
        return 999;
      }
    },
    localStorageToShoppingCartList(addToShoppingList) {
      let localStorageItems = JSON.parse(localStorage.getItem(addToShoppingList ? 'localShoppingList' : 'localShoppingCart') || false)
      if (!localStorageItems) {
        return false;
      }
      localStorageItems.forEach((item) => {
        this.addToShoppingCartRequest(item, addToShoppingList)
      })
      localStorage.setItem((addToShoppingList ? 'localShoppingList' : 'localShoppingCart'), [])
    },
    getHelpSystemUrl() {
      if (this.$root.$i18n.locale == 'de') {
        return this.helpSystemUrlDe;
      } else {
        return this.helpSystemUrlEn;
      }
    },
    getVerificationProcessState(status) {
      if (status == "CONFIRMED") {
        return "green";
      } else if (
        status == "VERIFICATION_REQUESTED" ||
        status == "10_DAY_REMINDER"
      ) {
        return "yellow";
      } else if (
        status == "FAILED_WITH_SUSPENSION" ||
        status == "FAILED_WITHOUT_SUSPENSION"
      ) {
        return "red";
      } else if (
        status == "NOT_VERIFIED" ||
        status == "REVOKED"
      ) {
        return "gray";
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFqdn(name, zone) {
      if (name != '') {
        return name + '.' + zone;
      }
      return zone;
    }
  },
  get methods() {
    return this._methods
  },
  set methods(value) {
    this._methods = value
  }
}
