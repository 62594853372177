<template>
  <label :class="{
      'checkbox-container': !!isStandardStyle,
      'checkbox-container-gray': !isStandardStyle,
    }"
  >
    <input
      :name="name"
      :id="(id=='' ? name : id)"
      :type="type"
      :checked="checkedValue"
      @click="handleChange((value==false?true:false))"
      :class="{
        'is-invalid': !!errorMessage,
        'is-valid': !errorMessage && type!='checkbox',
      }"
    />

    <span class="checkmark"></span>

    <label :for="(id=='' ? name : id)" >
      <router-link v-if="linkLabel" :to="linkLabel">{{ label }}</router-link>
      <span v-if="!linkLabel">{{ label }}</span>
      <slot></slot>
    </label>

    <div class="invalid-feedback" v-show="errorMessage || (typeof meta.valid !== 'undefined' && meta.valid)">
      {{ errorMessage }}
    </div>

  </label>
</template>

<script>
import { useField } from "vee-validate";
export default {
  props: {
    type: {
      type: String,
      default: "checkbox",
    },
    value: {
      type: null
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: "",
      required: false,
    },
    linkLabel: {
      type: String,
      required: false,
    },
    validateTrue: {
      type: Boolean,
      default: false
    },
    checkedValue: {
      type: Boolean,
      default: false
    },
    isStandardStyle: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    let rules = undefined
    if(props.validateTrue){
      rules = {is: true}
    }
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      checked,
      //value
    } = useField(
      props.name,
      rules,
      {
        type: 'checkbox',
        //valueProp: value,
        initialValue: false
      }
      );
    return {
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      checked,
      handleChange,
    };
  },
};
</script>
